<template>
    <div>
        <el-breadcrumb
            separator-class="el-icon-arrow-right"
            v-if="messageTab == 1"
        >
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>贸促动态</el-breadcrumb-item>
        </el-breadcrumb>
        <el-breadcrumb
            separator-class="el-icon-arrow-right"
            v-if="messageTab == 2"
        >
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>会员之家</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="content">
            <div
                v-for="item in list"
                :key="item.type"
                class="view"
                @click="detail(item)"
            >
                <div class="title">
                    <span style="color: blue; margin-right: 10px"
                        >[{{ item.createTime.split(' ')[0] }}]</span
                    >
                    <span>{{ item.title }}</span>
                </div>
            </div>
            <div style="text-align: center; margin-top: 20px">
                <el-pagination
                    layout="prev, pager, next"
                    :total="total"
                    @current-change="currentPage"
                    @prev-click="shang"
                    @next-click="xia"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { Server } from "@/server";
// import { type } from "os";
export default {
    name: "notice",
    data() {
        return {
            messageTab: 1, //1 贸促动态  2新闻之家
            pageSize: 10,
            pageNo: 1,
            total: 0,
            list: [], //列表
            timeList: [],
            type: '', // 类型
        };
    },
    watch: {
        pageNo() {
            this.gettype();
        },
    },
    methods: {
        detail(item) {
            this.$router.push({
                path: "/noticedetail",
                query: {
                    messageTab: this.messageTab,
                    type: item.type,
                    id: item.id,
                },
            });
        },
        currentPage(page) {
            this.pageNo = page;
        },
        xia() {
            this.pageNo = this.pageNo + 1;
        },
        shang() {
            this.pageNo = this.pageNo - 1;
        },
        gethyzj() {
            Server.infoList({
                type: 12,
                status: 1,
                pageSize: this.pageSize,
                pageNo: this.pageNo,
            }).then((res) => {
                if (res.code == 200) {
                    this.list = res.result.records;
                    // this.handleTime();
                    this.total = res.result.total;
                }
            });
        },
        getmcdt() {
            Server.infoList({
                type: 1,
                status: 1,
                pageSize: this.pageSize,
                pageNo: this.pageNo,
            }).then((res) => {
                console.log(res);
                if (res.code == 200) {
                    this.list = res.result.records;
                    // this.handleTime();
                    this.total = res.result.total;
                }
            });
        },
        gettype() {
            if (this.messageTab == 1) {
                this.getmcdt();
            } else {
                this.gethyzj();
            }
        },
        // handleTime() {
        //     if(this.list.length >= 0 && this.list != null) {
        //         this.timeList = []
        //         this.list.forEach((item) => {
        //             if(item.createTime != '' && item.createTime != null) {
        //                 var timesplit = item.createTime.split(" ")
        //                 this.timeList.push({
        //                     time: timesplit[0]
        //                 })
        //                 console.log(this.timeList)
        //             }
        //             console.log(this.timeList)
        //         })
        //     }
        // }
    },
    mounted() {
        this.messageTab = this.$route.query.messageTab;
        this.type = this.$route.query.messageTab;
        // this.type = this.$route.query.type
        this.id = this.$route.query.id;
        this.gettype();
        this.$store.state.CommonHeader.tab = 11;
    },
};
</script>

<style lang="less" scoped>
.el-breadcrumb {
    padding: 20px 0 10px 10px;
}
.content {
    padding: 30px 0;
    .view {
        padding: 20px;
        background-color: #cccccc;
        cursor: pointer;
        .text {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            word-break: break-all;
        }
        .title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            font-size: 20px;
        }
    }
    .view:nth-child(2n) {
        background-color: #ffffff;
    }
    .view:hover {
        opacity: 0.8;
    }
}
</style>
