<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/rencaipeixun' }">人才培训</el-breadcrumb-item>
            <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="content">
            <div class="title">{{ list[0].title }}</div>
            <div
                v-for="(item, index) in list"
                :key="index"
                v-html="item.content"
                style="line-height: 1.6"
            ></div>
        </div>
    </div>
</template>

<script>
import { Server } from "@/server";

export default {
    name: "rencaipeixunDetail",
    data() {
        return {
            // type: 6,
            id: "",
            index: 0,
            // messageTab: 21,
            pageSize: 10,
            pageNo: 1,
            total: 0,
            list: [], //列表
        };
    },
    watch: {
        pageNo() {
            this.getrcpx();
        },
    },
    methods: {
        currentPage(page) {
            this.pageNo = page;
        },
        xia() {
            this.pageNo = this.pageNo + 1;
        },
        shang() {
            this.pageNo = this.pageNo - 1;
        },
        getrcpx() {
            console.log(this.type)
            Server.infoList({
                type: this.type,
                id: this.id,
                index: this.index,
                status: 1,
                pageSize: this.pageSize,
                pageNo: this.pageNo,
            }).then((res) => {
                console.log(res);
                if (res.code == 200) {
                    this.list = res.result.records;
                    this.total = res.result.total;
                }
            });
        },
    },
    mounted() {
        console.log(this.$route.query.type);
        this.type = this.$route.query.type;
        this.id = this.$route.query.id
        this.getrcpx();
        // this.$store.state.CommonHeader.tab = 11
    },
};
</script>

<style lang="less" scoped>
.el-breadcrumb {
    padding: 20px 0 10px 10px;
}
.content {
    background-color: #ffffff;
    padding: 30px 10px 30px 10px;
    margin: 0 0 10px 0;
    line-height: 2;
    .file {
        color: blue;
        padding: 10px;
    }
    .time {
        font-size: 18rpx;
        color: #999999;
        margin: 20px 0;
        text-align: right;
    }
    .title {
        font-size: 24px;
        text-align: center;
        margin-bottom: 20px;
    }
    .fb {
        background-color: #f5f5f5;
        font-size: 14px;
        text-align: center;
        height: 35px;
        line-height: 35px;
        margin-bottom: 10px;
    }
}
</style>
