<template>
    <div class="header">
        <div class="header-within">
            <img class="logo" src="../assets/image/logo.png" alt="">

            <div class="navigation" :default-active="$route.path">
                <div
                    class="view"
                    :class="tab == '0' ? 'isHover' : ''"
                    :style="tab == '0' ? 'background:#F24545; color: #f5f5f5;font-weight: bold;' : ''"
                    @click="tabClick(tab = 0)"
                    
                >
                    <div class="item">首页</div>
                </div>
                <div @click="tabClick(tab = 1)" style="height: 100%;">
                    <el-dropdown class="view" :class="tab == '1' ? 'isHover' : ''" :style="tab == '1' ? 'background:#F24545; color: #f5f5f5;font-weight: bold;' : ''" @command="toDropdownItem">
                        <div class="item">
                            官方资讯
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="saishitongzhi">赛事通知</el-dropdown-item>
                            <el-dropdown-item command="guanfangbaodao">官方报道</el-dropdown-item>
                            <el-dropdown-item command="jingcaishike">精彩时刻</el-dropdown-item>
                            <el-dropdown-item command="meitibaodao">媒体报道</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                
                
                <div @click="tabClick(tab = 2)" style="height: 100%;">
                    <el-dropdown class="view" :class="tab == '2' ? 'isHover' : ''" :style="tab == '2' ? 'background:#F24545; color: #f5f5f5;font-weight: bold;' : ''"  @command="toDropdownItem">
                        <div class="item">
                            精彩中运会
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command='zhongyunhuijianjie'>中运会简介</el-dropdown-item>
                            <el-dropdown-item command="saishiwenhua">赛事文化</el-dropdown-item>
                            <el-dropdown-item command="xuanchuanpian">宣传片</el-dropdown-item>
                            <el-dropdown-item command="saishifuwu">赛事服务</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div
                    class="view"
                    :class="tab == '3' ? 'isHover' : ''"
                    :style="tab == '3' ? 'background:#F24545; color: #f5f5f5;font-weight: bold;' : ''"
                    @click="tabClick(tab = 3)"
                >
                    <div class="item">成绩公告</div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
    name: "Header",
    data() {
        return {
            tab: this.$store.state.CommonHeader.tab ? this.$store.state.CommonHeader.tab : 0,
            select: '/',
        };
    },  
    created() {
        // 在页面加载时读取sessionStorage
        if (sessionStorage.getItem("select")) {
            this.$store.replaceState(
                Object.assign(
                    {},
                    this.$store.state,
                    JSON.parse(sessionStorage.getItem("select"))
                )
            );
        }
        // 在页面刷新时将store保存到sessionStorage里
        window.addEventListener("beforeunload", () => {
            sessionStorage.setItem("select", JSON.stringify(this.$store.state));
        });
    },
    watch: {
        counts(newVal, oldVal) {
            this.tab = newVal;
        },
        '$route':'getPath'
    },
    computed: {
        ...mapState("CommonHeader", ["nav", "Games", "isShowli"]),
        counts() {
            return this.$store.state.CommonHeader.tab;
        },
    },
    mounted() {
        console.log(this.$router);
        console.log(this.tab);
        this.$store.state.CommonHeader.tab = 0
        // this.counts = 0;
        this.tabClick(0);
    },
    methods: {
        getPath(){
            console.log(this.$route.path);
            let route = this.$route.path
            if(route == '/home') {
                this.tab = 0
            } else if (route == '/guanfangzixun') {
                this.tab = 1
            } else if (route == '/jingcaizhongyunhui') {
                this.tab = 2
            } else if (route == '/achievement') {
                this.tab = 3
            } 
            // else if (route == '/achievement') {
            //     this.tab = 4
            // } 
        },

        ...mapMutations("CommonHeader", ["Flagt", "Flagf"]),

        tabClick(tab) {
            this.$store.state.CommonHeader.tab = tab;
            this.tab = tab 
            console.log(tab);
            if (tab == 0) {
                // this.$router.push({
                //     path: "/home",
                //     query: {
                //         tab: this.tab
                //     },
                // });
                this.select = '/home'
                sessionStorage.setItem('tab', this.select)
                this.$router.push({
                    path: this.select,
                    query: {
                        tab: this.tab
                    },
                })
            } else if (tab == 1) {
                this.$router.push({
                    path: "/guanfangzixun",
                    query: {
                        tab: this.tab
                    },
                });

            } else if (tab == 2) {
                this.$router.push({
                    path: "/jingcaizhongyunhui",
                    query: {
                        tab: this.tab
                    },
                });
            } else if (tab == 3) {
                this.$router.push({
                    path: "/achievement",
                    query: {
                        tab: this.tab
                    },
                });
            }
        },

        toDropdownItem(name) {
            console.log(name);
            if(name == 'saishitongzhi') {
                this.$router.push({
                    path: '/gameNotice'
                })
            } else if (name == 'jingcaishike') {
                this.$router.push({
                    path: '/gradeTime'
                })
            } else if (name == 'saishiwenhua') {
                this.$router.push({
                    path: '/culture'
                })
            } else if (name == 'guanfangbaodao') {
                this.$router.push({
                    path: '/publicReport'
                })
            } else if (name == 'meitibaodao') {
                this.$router.push({
                    path: '/mediaReport'
                })
            } else if (name == 'zhongyunhuijianjie') {
                this.$router.push({
                    path: '/jingcaizhongyunhui',
                    query: {
                        activeName: '1'
                    }
                })
            } else if (name == 'xuanchuanpian') {
                this.$router.push({
                    path: '/jingcaizhongyunhui',
                    query: {
                        activeName: '3'
                    }
                })
            } else if (name == 'saishifuwu') {
                this.$router.push({
                    path: '/jingcaizhongyunhui',
                    query: {
                        activeName: '2'
                    }
                })
            }
        }
    },
    
};
</script>

<style lang="less" scoped>
.header {
    width: 100%;
    background-color: #f5f5f5;
    .header-within {
        width: 90%;
        margin: 0 auto;

        display: flex;
        align-items: center;
        position: relative;
        z-index: 10;
        .logo {
            width: 300px;
            height: 50px;
            margin-right: 100px;
            z-index: 100;
        }
        .navigation {
            display: flex;
            align-items: center;
            // padding: 0 20px;
            width: 100%;
            height: 70px;
            z-index: 10;
            .view {
                height: 100%;
                width: 185px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-size: 18px;
                color: #444;
                letter-spacing: 2px;
            }
            .item {
                width: 100%; 
                height: 100%; 
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover {
                    // background:#F24545; 
                    color: #F24545;
                    font-weight: bold;
                }
            }
            .view.isHover {
                .item:hover {
                    color: #fff;
                }
            }
        }
    }
    
}
.el-dropdown-menu {
    width: 185px;
    // background-color: #03A9F4;
    border: none;
    .el-dropdown-menu__item{
        text-align: center;
        margin: 10px 0;
        font-size: 18px;
        // color: #f5f5f5;
        &:hover {
            color: #F24545;
            font-weight: bold;
            background-color: #F5F5F5;
        }
        
    }
}

/* pad */
@media screen and (max-width: 1199px) and (min-width: 600px) {
.header {
    width: 100%;

    .header-within {
        width: 90%;
        margin: 0 auto;

        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 10;
        .logo {
            width: 15%;
            height: 30px;
            // margin-right: 100px;
            z-index: 100;
        }
        .navigation {
            flex: 1;

            display: flex;
            align-items: center;
            // padding: 0 20px;
            height: 70px;
            z-index: 10;
            .view {
                height: 100%;
                width: 20%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-size: 18px;
                color: #666;
            }
        }
    }
}
}
/* 移动端 */
</style>
