<template>
  <div>
    <el-breadcrumb>
    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item :to="{ path: '/rencaizhengce' }">人才政策</el-breadcrumb-item>
    <el-breadcrumb-item :to="{ path: '/search' }">查询</el-breadcrumb-item>
    <el-breadcrumb-item :to="{ path: '/searchlist'}">查询结果</el-breadcrumb-item>
    <el-breadcrumb-item >详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <div class="title">{{info.fbt}}</div>
      <div  style="font-size: 13px;padding-bottom: 10px;margin-right: 15px;float: right;padding-right: 30px">政策名称:{{info.title}}</div>
      <!-- <div class="time">{{info.fbtime}}</div> -->
      <div><label class="mylabel"> 支持内容</label>
      <div class="mydiv"  v-html="info.content"></div>
      </div>
      <div><label class="mylabel"> 办理单位:</label>
      <div class="mydiv" >{{info.bldw}}</div>
      </div>
      <div><label class="mylabel"> 联系电话:</label>
      <div class="mydiv">{{info.lxfs}}</div>
      </div>
    </div>
  </div>

</template>

<script>
import { Server} from "@/server";
export default {
  name: "noticedetail",
  data() {
    return {
      info: [],
      id: '',
    };
  },
  methods: {
    getdata(){
      Server.fwZcxzDetail({
        id: this.id
      }).then(res=>{
    		if(res.code == 200){
          this.info = res.result
        }
    	})
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.getdata()
  },
};
</script>

<style lang="less" scoped>
  .el-breadcrumb{
    margin: 10px 0 10px 0;
  }
  .content{
    background-color: #FFFFFF;
    padding: 30px 10px 30px 10px;
    margin:0 0 10px 0;
    line-height: 2;

    .time{
      font-size: 18rpx;
      color: #999999;
      margin: 20px 0;
      text-align: right;
    }
    .title{
      font-size: 24px;
      text-align: center;
      margin-bottom: 20px;
    }
    .mylabel{
      color: #333;
      font-weight: bold;
      vertical-align: middle;
      font-size: 18px;
    }
    .mydiv{
      padding-top: 10px;
      font-size: 15px;
      padding-bottom: 10px;
      padding-left: 20px;
    }
  }
</style>
