<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>商事服务</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="model">
            <div class="item">
                <a href="https://app.singlewindow.cn/cas/login?service=https%3A%2F%2Fswapp.singlewindow.cn%2Fdeskserver%2Fj_spring_cas_security_check">
                    <img src="../../assets/img/shangshifuwu01.png" alt="">
                    <div class="desc">
                        <div class="word">贸促会原产地证书</div>
                        <div class="word">中国国际贸易单一窗口</div>
                    </div>
                </a>
            </div>
            <div class="item">
                <a href="https://co.ccpit.org/front/serviceNetWork">
                    <img src="../../assets/img/shangshifuwu02.png" alt="">
                    <div class="desc">
                        <div class="word">签证机构查询</div>
                    </div>
                </a>
            </div>
            <div class="item">
                <a href="http://fta.mofcom.gov.cn/index.shtml">
                    <img src="../../assets/img/shangshifuwu03.png" alt="">
                    <div class="desc">
                        <div class="word">优惠税率查询</div>
                    </div>
                </a>
            </div>
            <div class="item">
                <a href="https://co.ccpit.org/front/thirdPlatform">
                    <img src="../../assets/img/shangshifuwu04.png" alt="">
                    <div class="desc">
                        <div class="word">第三方平台查询</div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { Server } from "@/server";
// import { type } from "os";
export default {
    name: "shangshifuwu",
    data() {
        return {
            pageSize: 10,
            pageNo: 1,
            total: 0,
            list: [], //列表
        };
    },
    methods: {
        currentPage(page) {
            this.pageNo = page;
        },
        xia() {
            this.pageNo = this.pageNo + 1;
        },
        shang() {
            this.pageNo = this.pageNo - 1;
        },
        getzstz() {
            Server.infoList({
                type: 18,
                status: 1,
                pageSize: 10,
                pageNo: 1,
            }).then((res) => {
                if (res.code == 200) {
                    this.list = res.result.records;
                    this.total = res.result.total;
                }
            });
        },
    },
    mounted() {
        this.type = this.$route.query.type
        this.id = this.$route.query.id;
        this.getzstz();
        this.$store.state.CommonHeader.tab = 11;
    },
};
</script>

<style lang="less" scoped>
.el-breadcrumb {
    padding: 20px 0 10px 10px;
}

.model {
        padding: 50px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        .item {
            width: 210px;
            height: 170px;
            // background-color: #f9f9f9;
            padding: 15px 20px;
            box-sizing: border-box;
            border-radius: 15px;
            border: 1px dashed #e0e0e0;
            a {
                text-decoration: none;
                list-style: none;
                display: block;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                img {
                    display: block;
                    width: 50px;
                    height: 60px;
                    margin-bottom: 20px;
                }
                .desc {
                    width: 100%;
                    height: 50px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                }
                .word {
                    width: 100%;
                    text-align: center;
                    // margin: 10px 0;
                }
            }

            &:hover {
                border: none;
                box-shadow: 0 0 5px #d0d0d0;
                transition: all .3s ease-in-out;
            }
        }
    }
</style>
