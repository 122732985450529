<template>
    <div>
        <el-breadcrumb
            separator-class="el-icon-arrow-right"
            v-if="messageTab == 1"
        >
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/notice?type=1&messageTab=1' }"
                >贸促动态</el-breadcrumb-item
            >
            <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb>
        <el-breadcrumb
            separator-class="el-icon-arrow-right"
            v-if="messageTab == 2"
        >
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/notice?type=2&messageTab=2' }"
                >会员之家</el-breadcrumb-item
            >
            <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="content">
            <div class="title">{{ info.length == 0 ? "" : info.title }}</div>
            <div class="fb">
                发布时间：{{
                    info.releaseTime
                }}&nbsp;&nbsp;浏览量：{{ info.pageView }}
            </div>
            <!-- <div class="time">{{info[0].createTime}}</div> -->
            <div
                v-html="info.content"
                style="line-height: 1.6"
            ></div>
            <div
                v-for="(item, index) in itemFiles"
                :key="index"
                @click="download(item.url)"
                class="file"
            >
                附件：{{ item.name }}
            </div>
        </div>
    </div>
</template>

<script>
import { Server } from "@/server";
export default {
    name: "noticedetail",
    data() {
        return {
            messageTab: 1, //1贸促动态  2会员之家
            info: [],
            type: 1,
            id: "",
            index: 0,

            itemFiles: [], // 附件列表
        };
    },
    methods: {
        download(url) {
            window.open(this.$downloadUrl + url);
        },
        // getArrIndex(arr, obj) {
        //     let index = null;
        //     let key = Object.keys(obj)[0];
        //     arr.every(function (value, i) {
        //         if (value[key] === obj[key]) {
        //             index = i;
        //             return false;
        //         }
        //         return true;
        //     });
        //     return index;
        // },
        handle() {
            console.log(123);
            if (this.info.files != null) {
                this.itemFiles = [];
                var fileList = this.list.files.split(",");
                fileList.forEach((item) => {
                    var filesplit = item.split("/");
                    console.log(filesplit);
                    this.itemFiles.push({
                        name: filesplit[1],
                        url: item,
                    });
                    // console.log(this.itemFiles);
                });
            }
        },
        getmcdt() {
            // this.index = this.info.findIndex(item => item.id === this.id)
            Server.viewCountList({
                type: this.type,
                id: this.id,
                index: this.index,
            }).then((res) => {
                if (res.code == 200) {
                    this.info = res.result;
                    this.handle();
                }
            });
        },
        gethyzj() {
            Server.viewCountList({
                type: this.type,
                id: this.id,
            }).then((res) => {
                if (res.code == 200) {
                    this.info = res.result;
                    this.handle();
                }
            });
        },
        gettype() {
            if (this.messageTab == 1) {
                this.getmcdt();
            } else {
                this.gethyzj();
            }
        },
    },
    mounted() {
        this.messageTab = this.$route.query.messageTab;
        this.type = this.$route.query.type;
        this.id = this.$route.query.id;
        this.gettype();
        this.$store.state.CommonHeader.tab = 11;
    },
};
</script>

<style lang="less" scoped>
.el-breadcrumb {
    padding: 20px 0 10px 10px;
}
.content {
    background-color: #ffffff;
    padding: 30px 10px 30px 10px;
    margin: 0 0 10px 0;
    line-height: 2;
    .file {
        color: blue;
        padding: 10px;
    }
    .time {
        font-size: 18rpx;
        color: #999999;
        margin: 20px 0;
        text-align: right;
    }
    .title {
        font-size: 24px;
        text-align: center;
        margin-bottom: 20px;
    }
    .fb {
        background-color: #f5f5f5;
        font-size: 14px;
        text-align: center;
        height: 35px;
        line-height: 35px;
        margin-bottom: 10px;
    }
}
</style>
