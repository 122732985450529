<template>
    
    <div class="box">
        <div class="login">
            <a class="login-desc" target="_blank" href="http://zbxsty.cn:8086">登录 &nbsp;&nbsp;| &nbsp;&nbsp;注册</a>
        </div>
        <el-carousel height="1340px" :interval="3000" style="width: 100%; position: absolute;top: 0; z-index: 0;" arrow="never">
            <el-carousel-item>
                <img src="../../assets/image/first/one-1.jpg" alt="" style="z-index: -1; width: 100%;">
            </el-carousel-item>
            <el-carousel-item>
                <img src="../../assets/image/first/two-1.jpg" alt="" style="z-index: -1; width: 100%;">
            </el-carousel-item>
            <el-carousel-item>
                <img src="../../assets/image/first/three-1.jpg" alt="" style="z-index: -1; width: 100%;">
            </el-carousel-item>
        </el-carousel>
        <img class="bottom-bg" src="../../assets/image/bottomBg.png" alt="">

        <div class="content" style="padding-bottom: 0;">
            <div class="one">
                <div class="item">
                    <div class="subtitle">市级赛事</div>
                    <div class="dropdown">
                        <el-dropdown trigger="click">
                            <span class="el-dropdown-link">
                                <span style="flex: 1; text-align: center;" >淄博市第十七届中学生运动会赛会 </span><i class="down" style="border-top-color: #666;"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <!-- <el-dropdown-item>黄金糕</el-dropdown-item>
                                <el-dropdown-item>狮子头</el-dropdown-item>
                                <el-dropdown-item>螺蛳粉</el-dropdown-item>
                                <el-dropdown-item>蚵仔煎</el-dropdown-item> -->
                            </el-dropdown-menu>
                        </el-dropdown>
                        <!-- <div class="down"></div> -->
                    </div>
                    <div class="more">查看赛事</div>
                </div>
                <div class="item">
                    <div class="subtitle" style="color: #B71C1C;">区(县)级赛事</div>
                    <div class="dropdown">
                        <el-dropdown trigger="click">
                            <span class="el-dropdown-link">
                                <span style="flex: 1; text-align: center;" >暂无赛事 </span><i class="down"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <!-- <el-dropdown-item>黄金糕</el-dropdown-item>
                                <el-dropdown-item>狮子头</el-dropdown-item>
                                <el-dropdown-item>螺蛳粉</el-dropdown-item>
                                <el-dropdown-item disabled>双皮奶</el-dropdown-item>
                                <el-dropdown-item divided>蚵仔煎</el-dropdown-item> -->
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div class="more">查看赛事</div>
                </div>
                <div class="item">
                    <div class="subtitle">校级赛事</div>
                    <div class="dropdown">
                        <el-dropdown trigger="click">
                            <span class="el-dropdown-link">
                                <span style="flex: 1; text-align: center;" >暂无赛事 </span><i class="down"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <!-- <el-dropdown-item>黄金糕</el-dropdown-item>
                                <el-dropdown-item>狮子头</el-dropdown-item>
                                <el-dropdown-item>螺蛳粉</el-dropdown-item>
                                <el-dropdown-item disabled>双皮奶</el-dropdown-item>
                                <el-dropdown-item divided>蚵仔煎</el-dropdown-item> -->
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div class="more">查看赛事</div>
                </div>
            </div>
            <div class="two">
                <img class="two-img" src="../../assets/image/detail-bg.jpg" alt="">
                <div class="two-content">
                    <div class="subtitle">
                        <div class="title-desc">赛事通知</div>
                        <div class="more" @click="toGameMore()">
                            查看更多
                            <img class="more-icon" src="../../assets/image/right.png" alt="">
                        </div>
                    </div>
                    <ul class="ul-list">
                        <li class="item" v-for="(item, index) in gameNoticeList" :key="index" @click="toGameDetail(item)">
                            <div class="dot"></div>
                            <div class="doc-title">{{item.title}}</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="content" style="background-color: transparent;padding-top: 0;">
            <div class="three">
                <div class="three-box">
                    <div class="three-tab">
                        <img class="tab-img" src="../../assets/image/culture.png" alt="">
                        <div class="more" @click="toCultureMore()">
                            查看更多
                            <img src="../../assets/image/right.png" alt="">
                        </div>
                    </div>
                    <ul>
                        <li class="item" v-for="(item, index) in cultureList" @click="toCultureDetail(item)" >
                            <img class="item-img" :src="downloadUrl + item.coverpicture" alt="">
                            <div class="item-content">
                                <div class="item-title">{{item.title}}</div>
                                <div class="item-desc">
                                    <div class="contents" v-html="item.content"></div>
                                </div>
                                <div class="time">{{item.createTime.split(' ')[0]}}</div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="three-box">
                    <div class="three-tab">
                        <img class="tab-img" src="../../assets/image/wonderful.png" alt="">
                        <div class="more" @click="toGradeTimeMore()">
                            查看更多
                            <img src="../../assets/image/right.png" alt="">
                        </div>
                    </div>
                    <ul>
                        <li class="item" v-for="(item, index) in gradeTimeList" :key="index" @click="toGradeTimeDetail(item)">
                            <img class="item-img" :src="downloadUrl + item.coverpicture" alt="">
                            <div class="item-content">
                                <div class="item-title">{{item.title}}</div>
                                <div class="item-desc">
                                    <div class="contents" v-html="item.content"></div>
                                </div>
                                <div class="time">{{item.createTime.split(' ')[0]}}</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div style="height: 500px;"></div>
    </div>
</template>

<script>
import Vue from "vue";
import vueMiniPlayer from "vue-mini-player";
import "vue-mini-player/lib/vue-mini-player.css";
Vue.use(vueMiniPlayer);

import { Server } from "@/server";
import { createLogger } from "vuex";

export default {
    name: "home",
    data() {
        return {
            // tab: this.$store.state.CommonHeader.tab,
            
            gameNoticeList: [],
            cultureList: [],
            gradeTimeList: [],

            downloadUrl: ''
        };
    },
    
    components: {
    },
    
    methods: {
        getNoticeData() {
            Server.infoList({xcOrTz: '2', noticeType: '2'}).then((res) => {
                console.log(res);
                if(res.code == 200) {
                    this.gameNoticeList = res.result.records
                }
            })
        },
        getCultureData() {
            Server.infoList({xcOrTz: '1', publicityType: '4'}).then((res) => {
                console.log(res);
                if(res.code == 200) {
                    this.cultureList = res.result.records
                }
            })
        },
        getGradeTimeData() {
            Server.infoList({xcOrTz: '1', publicityType: '1'}).then((res) => {
                console.log(res);
                if(res.code == 200) {
                    this.gradeTimeList = res.result.records
                }
            })
        },
        toGameMore() {
            this.$router.push({
                path: '/gameNotice'
            })
        },
        toGameDetail(item) {
            this.$router.push({
                path: '/gameNoticeDetail',
                query: {
                    id: item.id,
                }
            })
        },
        toCultureMore() {
            this.$router.push({
                path: '/culture'
            })
        },
        toCultureDetail(item) {
            this.$router.push({
                path: '/cultureDetail',
                query: {
                    id: item.id,
                }
            })
        },
        toGradeTimeMore() {
            this.$router.push({
                path: '/gradeTime'
            })
        },
        toGradeTimeDetail(item) {
            this.$router.push({
                path: '/gradeTimeDetail',
                query: {
                    id: item.id,
                }
            })
        }
    },

    mounted() {
        this.getNoticeData()
        this.getCultureData()
        this.getGradeTimeData()

        this.downloadUrl = this.$downloadUrl
    },
};
</script>

<style lang="less" scoped>
.el-dropdown-menu {
    display: none;
}
.box {
    width: 100%;
    position: relative;
    .login {
        width: 160px;
        height: 58px;
        position: absolute;
        top: 0;
        right: 10%;
        z-index: 10;
        background-image: url(../../assets/image/login-bg.png);
        background-size: contain;

        display: flex;
        justify-content: center;
        align-items: center;

        color: #f5f5f5;
        font-size: 20px;
    }
    ::v-deep .el-carousel__indicators--horizontal {
        top: -1000px !important;
        .el-carousel__indicator {
            &:nth-child(1) {
                margin-left: 0;
            }
            &:nth-last-child(1) {
                margin-right: 0;
            }
        }
        .el-carousel__button {
            width: 70px;
            height: 8px;
            border-radius: 15px;
            margin: 0 15px;
        }
    }
    .bottom-bg {
        width: 100%;
        position: absolute;
        // top: 1400px;
        bottom: 0;
        z-index: 0;
    }

    .content {
        width: 85%;
        margin: 0 auto;
        padding: 50px 5%;
        background-color: #fff;
        border-radius: 30px;

        position: relative;
        top: 400px;
        .one {
            // width: 90%;
            margin: 0 auto 50px;

            display: flex;
            justify-content: space-between;
            .item {
            width: 30%;
                padding: 30px 50px;
                background-color: #fff;
                border-radius: 30px;
                box-shadow: 2px 4px 15px #ddd;
                &:nth-child(1) {
                    .dropdown {
                        ::v-deep .el-dropdown {
                            color: #666; 
                        }
                    }
                    
                }
                .subtitle {
                    font-size: 24px;
                    color: #01579B;
                    font-weight: bold;
                    letter-spacing: 1px;
                }
                .dropdown {
                    margin: 30px 0;
                    width: 100%;
                    padding: 0 15px;
                    background-color: #fff;
                    height: 55px;
                    border-radius: 30px;
                    box-shadow: 0 0 7px #ddd;

                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    

                    ::v-deep .el-dropdown {
                        width: 305px;
                        text-align: center;
                        color: #999;
                        .el-dropdown-link {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }
                    }
                    .down {
                        width: 0;
                        height: 0;
                        border: 8px solid transparent;
                        border-top-color: #aaa;
                        transform: translateY(25%);
                    }
                }
                .more {
                    cursor: pointer;
                    text-align: right;
                    color: #999;
                    font-size: 18px;
                    font-weight: bold;
                    letter-spacing: 1px;
                }
            }
        }

        .two {
            width: 100%;
            height: 420px;
            // overflow: hidden;

            display: flex;
            justify-content: space-between;
            .two-img {
                width: 50%;
                height: 100%;
                background-color: #f0f0f0;

                display: block;
            }
            .two-content {
                margin-left: 25px;
                flex: 1;
                width: 48%;
                .subtitle {
                    border-bottom: 1px solid #e5e5e5;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .title-desc {
                        width: 150px;
                        text-align: center;
                        padding-bottom: 10px;
                        border-bottom: 3px solid #F24545;

                        color: #F24545;
                        font-size: 20px;
                        font-weight: bold;
                        letter-spacing: 1px;
                        position: relative;
                        top: 2px;
                    }
                    .more {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        color: #999;
                        font-size: 14px;
                        .more-icon {
                            width: 15px;
                            height: 15px;
                            margin-left: 5px;
                        }
                    }
                }
                .ul-list {
                    .item {
                        margin: 20px 0;

                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        .dot {
                            width: 12px;
                            height: 12px;
                            margin: 0 10px;
                            border-radius: 50%;
                            background-color: #F24545;
                            box-shadow: -3px 3px 4px #F0D7C1;
                        }
                        .doc-title {
                            flex: 1;
                            // width: 68%;
                            color: #010101;
                            font-size: 18px;
                            letter-spacing: 1px;
                            
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        .three {
            margin-top: 70px;

            width: 100%;
            display: flex;
            justify-content: space-between;
            .three-box {
                width: 48%;
                .contents {
                    ::v-deep p {
                        &:nth-child(1) {
                            margin: 0 !important;
                        }
                    }  
                } 
                .three-tab {
                    margin-bottom: 30px ;
                    display: flex;
                    justify-content: space-between;
                    .tab-img {
                        width: 350px;
                        height: 60px;
                        
                    }
                    .more {
                        display: flex;
                        align-items: center;
                        color: #999;
                        font-size: 14px;

                        position: relative;
                        top: 10px;
                        cursor: pointer;
                        img {
                            width: 15px;
                            height: 15px;
                            margin-left: 5px;
                        }
                    }
                }
                &:nth-child(2) {
                    .three-tab {
                        .tab-img {
                            width: 200px;
                        }
                    }
                }
                ul {
                    .item {
                        padding: 15px;
                        background-color: #fff;
                        border-radius: 20px;

                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        box-shadow: 2px 3px 15px #ccc;
                        margin-bottom: 20px;

                        cursor: pointer;

                        .item-img {
                            width: 50%;
                            height: 200px;
                            margin-right: 15px;
                        }
                        .item-content {
                            flex: 1;
                            height: 200px;
                            line-height: 30px;

                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            .item-title {
                                font-size: 22px;
                                font-weight: bold;
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                            }
                            .item-desc {
                                color: #999;
                                text-indent: 2em;

                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;
                            }
                            .time {
                                text-align: right;
                                color: #575757;
                                font-size: 14px;
                                margin-right: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}


/* pad */
@media screen and (max-width: 1199px) and (min-width: 600px) {
.box {
    width: 100%;
    position: relative;
    .login {
        width: 160px;
        height: 58px;
        position: absolute;
        top: 0;
        right: 10%;
        z-index: 10;
        background-image: url(../../assets/image/login-bg.png);
        background-size: contain;

        display: flex;
        justify-content: center;
        align-items: center;

        color: #f5f5f5;
        font-size: 20px;
    }
    ::v-deep .el-carousel__indicators--horizontal {
        top: -1120px !important;
        // bottom: 10% !important;
        // top: -10% !important;
        .el-carousel__indicator {
            &:nth-child(1) {
                margin-left: 0;
            }
            &:nth-last-child(1) {
                margin-right: 0;
            }
        }
        .el-carousel__button {
            width: 50px;
            height: 5px;
            border-radius: 15px;
            margin: 0 15px;
        }
    }
    .bottom-bg {
        width: 100%;
        position: absolute;
        // top: 1400px;
        bottom: 0;
        z-index: 0;
    }

    .content {
        width: 85%;
        margin: 0 auto;
        padding: 50px 5%;
        background-color: #fff;
        border-radius: 30px;

        position: relative;
        top: 30vw;
        .one {
            // width: 90%;
            margin: 0 auto 50px;

            display: flex;
            justify-content: space-between;
            .item {
                width: 31%;
                padding: 30px 20px;
                background-color: #fff;
                border-radius: 30px;
                box-shadow: 2px 4px 15px #ddd;
                &:nth-child(1) {
                    .dropdown {
                        ::v-deep .el-dropdown {
                            color: #666; 
                        }
                    }
                    
                }
                .subtitle {
                    font-size: 20px;
                    color: #01579B;
                    font-weight: bold;
                    letter-spacing: 1px;
                }
                .dropdown {
                    margin: 30px 0;
                    width: 100%;
                    background-color: #fff;
                    height: 55px;
                    border-radius: 30px;
                    box-shadow: 0 0 7px #ddd;

                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    

                    ::v-deep .el-dropdown {
                        width: 100%;
                        text-align: center;
                        font-size: 14px;
                        color: #999;
                        .el-dropdown-link {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }
                    }
                    .down {
                        width: 0;
                        height: 0;
                        border: 8px solid transparent;
                        border-top-color: #aaa;
                        transform: translateY(25%);
                    }
                }
                .more {
                    cursor: pointer;
                    text-align: right;
                    color: #999;
                    font-size: 18px;
                    font-weight: bold;
                    letter-spacing: 1px;
                }
            }
        }

        .two {
            width: 100%;
            height: 420px;
            overflow: hidden;

            display: flex;
            justify-content: space-between;
            .two-img {
                width: 50%;
                height: auto;
                background-color: #f0f0f0;
            }
            .two-content {
                margin-left: 25px;
                flex: 1;
                width: 48%;
                .subtitle {
                    
                    border-bottom: 1px solid #e5e5e5;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .title-desc {
                        width: 150px;
                        text-align: center;
                        padding-bottom: 10px;
                        border-bottom: 3px solid #F24545;

                        color: #F24545;
                        // font-size: 20px;
                        font-weight: bold;
                        letter-spacing: 1px;
                        position: relative;
                        top: 2px;
                    }
                    .more {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        color: #999;
                        font-size: 14px;
                        margin-right: 10px;
                        .more-icon {
                            width: 15px;
                            height: 15px;
                            margin-left: 5px;
                        }
                    }
                }
                .ul-list {
                    .item {
                        margin: 20px 0;

                        display: flex;
                        align-items: center;
                        .dot {
                            width: 8px;
                            height: 8px;
                            margin: 0 5px;
                            border-radius: 50%;
                            background-color: #F24545;
                            box-shadow: -1px 1px 3px #F0D7C1;
                        }
                        .doc-title {
                            // width: 68%;
                            flex: 1;
                            color: #010101;
                            font-size: 14px;
                            letter-spacing: 1px;

                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        .three {
            margin-top: 50px;

            width: 100%;
            display: flex;
            justify-content: space-between;
            .three-box {
                width: 48%;
                .three-tab {
                    width: 100%;
                    margin-bottom: 30px;
                    display: flex;
                    justify-content: space-between;
                    .tab-img {
                        width: 70%;
                        height: 50px;
                        
                    }
                    .more {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        color: #999;
                        font-size: 13px;

                        position: relative;
                        top: 10px;
                        img {
                            width: 15px;
                            height: 15px;
                            margin-left: 5px;
                        }
                    }
                }
                &:nth-child(2) {
                    .three-tab {
                        .tab-img {
                            width: 150px;
                        }
                    }
                }
                ul {
                    .item {
                        padding: 15px;
                        background-color: #fff;
                        border-radius: 20px;

                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        box-shadow: 2px 3px 15px #ccc;
                        margin-bottom: 20px;

                        .item-img {
                            width: 50%;
                            height: 150px;
                            margin-right: 15px;
                        }
                        .item-content {
                            flex: 1;
                            height: 150px;
                            line-height: 27px;

                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            .item-title {
                                font-size: 16px;
                                font-weight: bold;

                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                            }
                            .item-desc {
                                color: #999;
                                text-indent: 2.2em;
                                line-height: 20px;

                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;
                            }
                            .time {
                                text-align: right;
                                color: #575757;
                                font-size: 12px;
                                margin-right: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}
}


:deep(.el-carousel__indicators--horizontal) {
    position: relative;
    top: 15px;
    text-align: center;
}
</style>
