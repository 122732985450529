var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/' }}},[_vm._v("首页")]),_c('el-breadcrumb-item',{attrs:{"to":{
                path: _vm.toPath,
                query: {
                    type: _vm.type,
                    title: _vm.toCTitle
                },
            }}},[_vm._v(_vm._s(_vm.toCTitle))]),_c('el-breadcrumb-item',[_vm._v("详情")])],1),_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.list.length == 0 ? "" : _vm.list.title))]),_c('div',{staticClass:"fb"},[_vm._v(" 发布时间："+_vm._s(_vm.list.releaseTime)+"  浏览量："+_vm._s(_vm.list.pageView)+" ")]),_c('div',{staticStyle:{"line-height":"1.6"},domProps:{"innerHTML":_vm._s(_vm.list.content)}}),_vm._l((_vm.itemFiles),function(item,index){return _c('div',{key:index,staticClass:"file",on:{"click":function($event){return _vm.download(item.url)}}},[_vm._v(" 附件："+_vm._s(item.name)+" ")])})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }