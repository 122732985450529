<template>
    <div class="footer">
        <div class="top">
            <div class="content">
                <!-- <div class="box">
                    <div class="title">关于我们</div>
                    <div class="desc">
                        <span class="word" >首页</span>
                        <span class="line" >|</span>
                        <span class="word">赛事通知</span>
                        <span class="line">|</span>
                        <span class="word">精彩时刻</span>
                        <span class="line">|</span>
                        <span class="word">文化建设</span>
                        <span class="line">|</span>
                        <span class="word">成绩公示</span>
                    </div>
                </div> -->
                <div class="box">
                    <div class="title">联系我们</div>
                    <div class="desc" style="margin-top: 7px; justify-content: space-between;">
                        <!-- <div class="word">联系我们：淄博市市直机关第二综合办公楼</div> -->
                        <div class="word">技术支持电话：0533-3587990</div>
                        <!-- <div class="word">电子邮件</div> -->
                    </div>
                </div>

            </div>
        </div>
        <div class="bottom">
            <a class="desc" target="_blank" href="https://beian.miit.gov.cn">鲁ICP备19063239号-7</a>
        </div>
    </div>
</template>

<script>
// import { Calendar } from "@element-ui";
export default {
    name: "Header",
    data() {
        return {
            
        };
    },
};
</script>

<style lang="less">
.top {
    background-color: #312a2a;
    .content {
        width: 80%;
        margin: 0 auto;
        padding: 50px 0;

        display: flex;
        justify-content: center;
        .box {
            width: 40%;
            .title {
                width: 100%;
                padding-bottom: 15px;
                border-bottom: 1px solid #c0c0c0;

                color: #f24545;
                font-size: 22px;
                font-weight: bold;
                letter-spacing: 1px;
            }
            .desc {
                color: #c0c0c0;
                font-size: 18px;
                font-weight: bold;
                margin-top: 30px;

                display: flex;
                flex-wrap: wrap;
                .line {
                    margin: 0 10px;
                }
                .word {
                    margin-bottom: 15px;
                }
            }
        }
    }
}
.bottom {
    width: 100%;
    height: 80px;
    background-color: #232323;
    display: flex;
    justify-content: center;
    align-items: center;
    .desc {
        display: block;
        text-align: center;
        font-size: 18px;
        color: #c0c0c0;
        font-weight: bold;
    }
}

@media screen and (max-width: 1199px) and (min-width: 600px) {
.top {
    background-color: #312a2a;
    .content {
        width: 90%;
        margin: 0 auto;
        padding: 30px 0;

        display: flex;
        justify-content: space-between;
        .box {
            width: 43%;
            .title {
                width: 100%;
                padding-bottom: 15px;
                border-bottom: 1px solid #c0c0c0;

                color: #f24545;
                font-size: 20px;
                font-weight: bold;
                letter-spacing: 1px;
            }
            .desc {
                color: #c0c0c0;
                font-size: 15px;
                font-weight: bold;
                margin-top: 20px;

                display: flex;
                flex-wrap: wrap;
                .line {
                    margin: 0 10px;
                }
                .word {
                    margin-bottom: 15px;
                }
            }
        }
    }
}
.bottom {
    width: 100%;
    height: 70px;
    background-color: #232323;
    display: flex;
    justify-content: center;
    align-items: center;
    .desc {
        display: block;
        text-align: center;
        font-size: 16px;
        color: #c0c0c0;
        font-weight: bold;
    }
}
}


</style>
