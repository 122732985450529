<template>
    <div class="page">
        <img class="logo" src="../../assets/image/logo.png" alt="">
        <img class="top-bg" src="../../assets/image/second/three.png" alt="">
        <img class="bottom-bg" src="../../assets/image/bottomBg.png" alt="">
        <!-- <img v-if="size == '1'" style="bottom: 1620px; opacity: .5; transform: rotate(180deg);" class="bottom-bg" src="../../assets/image/bottom-bg-1.png" alt=""> -->

        <div class="content">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/home?tab=0' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/gradeTime?tab=1' }">精彩时刻</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>

            <div class="title">
                {{title}}
            </div>
            <div class="base">
                <div>发布日期：{{createTime}}</div>
                <div class="info">
                    字号：
                    <span class="font-size" :class="{'select' : size == 1}" @click="fontSize(size = '1')">大</span>
                    <span class="font-size" :class="{'select' : size == 2}" @click="fontSize(size = '2')">中</span>
                    <span class="font-size" :class="{'select' : size == 3}" @click="fontSize(size = '3')">小</span>
                    <!-- | 
                    <span @click="print()">打印</span> -->
                </div>
            </div>
            <div class="desc" :class="size == '1' ? 'big' : (size == '3' ? 'small' : '')" >
                <img style="display: block; width: 50%;margin: 0 0 30px 25%;" :src="downloadUrl + url" alt="">
                <div class="contents" v-html="content"></div>
            </div>

            
        </div>
        <div style="height: 600px;"></div>
        <!-- <div style="height: 1000px;"></div> -->
    </div>
</template>

<script>
import { Server } from "@/server";
export default{
    name: 'gradeTime',
    data() {
        return {
            size: 0,
            scaleNum:1,

            downloadUrl: '',
            id: "",
            content: "", 

            title: "",
            createTime: "",

            url: "",
        };
    },
    computed: {
        counts() {
            return this.$store.state.CommonHeader.size;
        },
    },
    mounted() {
        this.size = this.counts;

        this.downloadUrl = this.$downloadUrl
        this.id = this.$route.query.id;

        this.getData()
    },
    methods: {
        fontSize(size) {
            this.$store.state.CommonHeader.fsize = size;
            console.log(size);
            this.size = size
            if(size == '1') {
                const container = document.querySelector('.font-size');
                if (container) {
                    this.containerHeight = container.clientHeight;
                }
                console.log(this.containerHeight);
                this.scaleNum = 1.2
            }
            if (size == '3') {
                this.scaleNum = 0.8
            }
        },

        getData() {
            Server.infoList({id: this.id}).then(res => {
                console.log(res);
                if(res.code == 200) {
                    this.title = res.result.records[0].title
                    this.createTime = res.result.records[0].createTime
                    this.content = res.result.records[0].content
                    this.url = res.result.records[0].coverpicture
                }
            })
        }
    }
}
</script>

<style lang="less" scoped> 
.page {
    width: 100%;
    position: relative;
    .logo {
        position: absolute;
        z-index: 11;
        margin-left: 10%;
    }
    .top-bg {
        width: 100%;
        position: absolute;
        top: -70px;
        z-index: 10;
    }
    .bottom-bg {
        width: 100%;
        height: 70%;
        position: absolute;
        bottom: 0;
        z-index: 0;
        object-fit:cover;
    }
    .content {
        width: 70%;
        margin: 0 auto;
        position: relative;
        top: 350px;
        background: linear-gradient(#fff 300px, transparent);
        border-radius: 20px;
        z-index: 10;

        padding-bottom: 20px;
        .title {
            width: 90%;
            text-align: center;
            margin: 60px auto 50px;
            font-size: 22px;
            font-weight: bold;
        }
        .base {
            width: 95%;
            margin: 0 auto;
            padding: 10px 0;
            border-bottom: 1px solid #ccc;

            color: #999;
            font-size: 14px;

            display: flex;
            justify-content: space-between;
            align-items: center;
            .info {
                margin-right: 20px;
            }
            .font-size {
                padding:3px;
                margin: 0 3px;
            }
            .select {
                // font-size: 16px;
                border: 1px solid #255499;
                color: #255499;
                font-weight: bold;
                border-radius: 5px;
            }
            
        }
        .desc {
            width: 90%;
            margin: 50px auto;
            line-height: 22px;
            ::v-deep p {
                background-color: transparent !important;
            }
        }
        .big {
            font-size: 1.2em !important;
            line-height: 30px;
        }
        .small {
            font-size: .8em !important;
            line-height: 15px;
        }
    }
}

@media screen and (max-width: 1199px) and (min-width: 600px) {
.page {
    width: 100%;
    position: relative;
    .logo {
        width: 20%;
        position: absolute;
        top: -10px;
        z-index: 11;
        margin-left: 10%;
    }
    .top-bg {
        width: 100%;
        position: absolute;
        top: -70px;
        z-index: 10;
    }
    .bottom-bg {
        width: 100%;
        height: 70%;
        position: absolute;
        bottom: 0;
        z-index: 0;
        object-fit:cover;
    }
    .content {
        width: 80%;
        margin: 0 auto;
        position: relative;
        top: 250px;
        background: linear-gradient(#fff 300px, transparent);
        border-radius: 20px;
        z-index: 10;

        padding-bottom: 20px;
        .title {
            width: 80%;
            text-align: center;
            margin: 60px auto 50px;
            font-size: 22px;
            font-weight: bold;
        }
        .base {
            width: 90%;
            margin: 0 auto;
            padding: 10px 0;
            border-bottom: 1px solid #ccc;

            color: #999;
            font-size: 14px;

            display: flex;
            justify-content: space-between;
            align-items: center;
            .info {
                margin-right: 10px;
            }
            .font-size {
                padding:3px;
                margin: 0 3px;
            }
            .select {
                // font-size: 16px;
                border: 1px solid #255499;
                color: #255499;
                font-weight: bold;
                border-radius: 5px;
            }
            
        }
        .desc {
            width: 90%;
            margin: 50px auto;
            line-height: 17px;
            font-size: 14px;
        }
        .big {
            font-size: 16px;
            line-height: 19px;
        }
        .small {
            font-size: 12px;
            line-height: 15px;
        }
    }
}
}
</style>