export const httpurl = "https://www.zbxsty.cn"
// const httpurl = "http://192.168.10.3:8080";
// export const httpurl = "http://192.168.10.7:8080/jeecg-boot/";


// const httpurl = 'http://localhost:8080'
//引入axios和qs插件
import axios from "axios";
import qs from "qs";
//创建一个类并导出
export const Server = new (class {
    constructor() {
        //请求接口路径
        this.loginApi = "/api/user/getuserinfo"; //根据dapp 地址，返回对应的中心化用户信息
        // this.gonggaoList = '/jeecg-boot/wz/wzTzgg/listwz';//公告列表
        // this.newList = '/jeecg-boot/wz/wzNews/listwz';//新闻列表
        // this.gongaoDetail = '/jeecg-boot/wz/wzTzgg/liulan';//公告详情
        // this.newDetail = '/jeecg-boot/wz/wzNews/liulan';//新闻详情
        // this.rezhengList = '/jeecg-boot/ymtb/fwZcxz/list/web';//人才政策的列表
        // this.rezhengDetail = '/jeecg-boot/ymtb/fwZcxz/queryById';//人才政策的详情

        // this.releaseList = "/release/releaseManage/list"; // 贸促动态列表
        // this.viewCount = "/release/releaseManage/pageView"; // 带浏览量的查看
        
        this.newsInfoList = '/tzxc/notificationAndPromotion/promotionList'; // 新闻宣传查看
        this.noticeInfoList = '/tzxc/notificationAndPromotion/notificationList'; // 赛事通知查看

        this.infoLists = '/tzxc/notificationAndPromotion/promotionFastList'; // 信息列表
    }

    // //公告获取
    // wzTzggList(params){
    // 	return axios.get(httpurl + this.gonggaoList,  {params: params}).then((res) => res.data)
    // }
    // //新闻获取
    // wzNewsList(params){
    // 	return axios.get(httpurl + this.newList,  {params: params}).then((res) => res.data)
    // }
    // //公告详情
    // wzTzggDetail(params){
    // 	return axios.get(httpurl + this.gongaoDetail,  {params: params}).then((res) => res.data)
    // }
    // //新闻详情
    // wzNewDetail(params){
    // 	return axios.get(httpurl + this.newDetail,  {params: params}).then((res) => res.data)
    // }
    // //人才政策列表
    // fwZcxzList(params){
    // 	return axios.get(httpurl + this.rezhengList,  {params: params}).then((res) => res.data)
    // }
    // //人才政策关键字搜索
    // fwKeywordList(params){
    // 	return axios.get(httpurl + this.keywordList,  {params: params}).then((res) => res.data)
    // }
    // //人才政策详情
    // fwZcxzDetail(params){
    // 	return axios.get(httpurl + this.rezhengDetail,  {params: params}).then((res) => res.data)
    // }

    // 贸促动态
    // infoList(params) {
    //     let param = {
    //         ...params,
    //         column: 'releaseTime',
    //         order: 'desc'
    //     }
    //     return axios
    //         .get(httpurl + this.releaseList, { params: param })
    //         .then((res) => res.data);
    // }

    // 带浏览量的列表
    viewCountList(params) {
        return axios.get(httpurl + this.viewCount, {params: params}).then((res) => res.data)
    }
    tabList(params) {
        return axios
            .get(httpurl + this.infosList, { params: params })
            .then((res) => res.data);
    }
    newsList(params) {
        return axios.get(httpurl + this.newsInfoList, {params: params}).then((res) => res.data)
    }
    noticeList(params) {
        return axios.get(httpurl + this.noticeInfoList, {params: params}).then((res) => res.data)
    }

    infoList(params) {
        return axios.get(httpurl + this.infoLists, {params: params}).then(res => res.data)
    }
})();
