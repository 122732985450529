export default ({
    namespaced: true,
    actions: {},
    mutations: {
        Flagt(state){
            state.isShowli = true
        },
        Flagf(state){
            state.isShowli = false
        },
        changeTab(state,num){
            state.tab = num
        }
    },
    state: {
        tab:0,
    },
    getters: {}
})