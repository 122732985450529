import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import ElementUI from 'element-ui';             //全局引入element
import 'element-ui/lib/theme-chalk/index.css';    //全局引入element的样式
import { Container,Header,Main,Footer,Card } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './style/index.less'
import vueMiniPlayer from 'vue-mini-player'
import 'vue-mini-player/lib/vue-mini-player.css'
import { httpurl } from './server';


Vue.prototype.$downloadUrl= httpurl + '/sys/common/static/';
Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Card)
Vue.use(ElementUI)
Vue.use(vueMiniPlayer)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
