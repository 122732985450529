export default ({
    namespaced: true,
    actions: {},
    mutations: {
        roy(state, Name) {
            state.roys = Name
            // console.log(state.roys);
        },
        remen(state, Name) {
            state.remens = Name
            console.log(state.remens);
        },
        firstCals(state) {
            state.roys = state.synthetical.cals
            state.remens = state.selectLists.zzjx
            console.log(state.roys);
        }
    },
    state: {
        roys: [],
        remens: [],
        titleList: ['灵魂莲华2022', '全球总决赛通行证', 'S12小组赛', 'TOC4航海之旅', '神龙尊者 小小艾希'],
        images: [
            'https://ossweb-img.qq.com/upload/adw/image/977/20221008/fbcf457ee13a8362d0254cc09880671c.jpeg',
            'https://ossweb-img.qq.com/upload/adw/image/977/20221008/2c8f6f1ebdcf422fe4a8ca31b9b96608.jpeg',
            'https://ossweb-img.qq.com/upload/adw/image/977/20221010/5d65b5ed24ef77062d66e0f91ef86d21.jpeg',
            'https://ossweb-img.qq.com/upload/adw/image/977/20221009/f619839091f23c9d3eb1d4f37c894c57.jpeg',
            'https://ossweb-img.qq.com/upload/adw/image/977/20221008/c68ef8782d2e55c359d9efebc71cdc98.jpeg'
        ],
        news: [
            { Name: 'cals', Tit: '综合' },
            { Name: 'notice', Tit: '公告' },
            { Name: 'Competition', Tit: '赛事' },
            { Name: 'strategy', Tit: '攻略' },
            { Name: 'community', Tit: '社区' },
        ],
        synthetical: {
            HH: [
                '一瞬心间，一念绽灵 | 绽灵节·灵魂莲华2022现已盛放',
                '云顶之弈【航海之旅】及【航海探秘】 活动限时销售公告',
                '英雄麦克疯：三战全胜渐入佳境',
                '版本研究所: S13季前赛前瞻，野区改动',
                '绽灵节背景故事一图介绍！'
            ],
            cals: [
                {
                    title: '新闻',
                    content: '云顶之弈【航海之旅】及【航海探秘】 活动限时销售公告',
                    times: '10-08',
                    class: 'skyblue'
                },
                {
                    title: '公告',
                    content: '灵魂莲华2022皮肤与全球总决赛通行证现已上线',
                    times: '10-07',
                    class: 'yellow'
                },
                {
                    title: '公告',
                    content: '12.19云顶之弈版本更新公告',
                    times: '10-07',
                    class: 'yellow'
                },
                {
                    title: '公告',
                    content: '12.19版本更新公告：辛德拉中型更新，灵魂莲华系列全新皮肤上线',
                    times: '10-07',
                    class: 'yellow'
                },
                {
                    title: '新闻',
                    content: '2022峡谷传说原画预览',
                    times: '10-06',
                    class: 'yellow'
                },
                {
                    title: '视频',
                    content: '2022峡谷传说皮肤预览',
                    times: '10-06',
                    class: 'orange'
                }
            ],
            notice: [
                {
                    title: '公告',
                    content: '灵魂莲华2022皮肤与全球总决赛通行证现已上线',
                    times: '10-07',
                    class: 'yellow'
                },
                {
                    title: '公告',
                    content: '12.19云顶之弈版本更新公告',
                    times: '10-07',
                    class: 'yellow'
                },
                {
                    title: '公告',
                    content: '12.19版本更新公告：辛德拉中型更新，灵魂莲华系列全新皮肤上线',
                    times: '10-07',
                    class: 'yellow'
                },
                {
                    title: '公告',
                    content: '2023季前赛：前瞻',
                    times: '10-03',
                    class: 'yellow'
                },
                {
                    title: '公告',
                    content: '9月30日周免英雄更新公告',
                    times: '09-30',
                    class: 'yellow'
                },
                {
                    title: '公告',
                    content: '灵魂莲华2022预售现已开启',
                    times: '09-28',
                    class: 'yellow'
                }
            ],
            Competition: [
                {
                    title: '视频',
                    content: '2022全球总决赛小组赛第二日TOP5',
                    times: '10-09',
                    class: 'orange'
                },
                {
                    title: '赛事',
                    content: '2022全球总决赛小组赛赛程更新',
                    times: '10-09',
                    class: 'blue'
                },
                {
                    title: '视频',
                    content: '2022全球总决赛小组赛第一日TOP5',
                    times: '10-08',
                    class: 'orange'
                },
                {
                    title: '赛事',
                    content: '2022全球总决赛小组赛第一日，LPL战队赛程预告',
                    times: '10-07',
                    class: 'blue'
                },
                {
                    title: '视频',
                    content: '英雄麦克疯：RNG有惊无险 成功突围',
                    times: '10-06',
                    class: 'orange'
                },
                {
                    title: '赛事',
                    content: '2022全球总决赛小组赛赛程更新',
                    times: '10-05',
                    class: 'blue'
                }
            ],
            strategy: [
                {
                    title: '视频',
                    content: '版本研究所: S13季前赛前瞻，装备篇（一）',
                    times: '10-06',
                    class: 'orange'
                },
                {
                    title: '教学',
                    content: '国服第一辛德拉资深重做分析&玩法预测',
                    times: '10-04',
                    class: 'green'
                },
                {
                    title: '教学',
                    content: '【徐小涵】打破辅助女枪的桎梏！正确出装解锁新玩法！',
                    times: '10-03',
                    class: 'green'
                },
                {
                    title: '教学',
                    content: '【徐小涵】暗爪主Q乌迪尔的爆发！三拳秒人伤害拉满！',
                    times: '10-02',
                    class: 'green'
                },
                {
                    title: '视频',
                    content: '12.19版本改动：辛德拉技能小重做 狗头QWR重获加强 ',
                    times: '09-30',
                    class: 'orange'
                },
                {
                    title: '视频',
                    content: '一看就会玩-荣耀行刑官 德莱文',
                    times: '09-27',
                    class: 'orange'
                }
            ],
            community: [
                {
                    title: '娱乐',
                    content: '【总结】全球总决赛（2022）通行证门票',
                    times: '10-08',
                    class: 'skyblue'
                },
                {
                    title: '视频',
                    content: '“好好看看小朋友”【2022灵魂莲华】',
                    times: '10-08',
                    class: 'orange'
                },
                {
                    title: '视频',
                    content: '【皮肤背景】瑟提的悲惨童年，易大师被徒弟背叛。',
                    times: '10-07',
                    class: 'orange'
                },
                {
                    title: '娱乐',
                    content: '【12.19版本】灵魂莲华德莱厄斯皮肤鉴赏',
                    times: '10-04',
                    class: 'green'
                },
                {
                    title: '娱乐',
                    content: '【12.19版本】灵魂莲华崔丝塔娜皮肤鉴赏',
                    times: '09-30',
                    class: 'green'
                },
                {
                    title: '娱乐',
                    content: '【12.19版本】灵魂莲华伊芙琳皮肤鉴赏',
                    times: '09-29',
                    class: 'green'
                }
            ]
        },
        selectList: [
            { Name: 'zzjx', Tit: '正在进行' },
            { Name: 'scth', Tit: '商城特惠' },
            { Name: 'cqhd', Tit: '长期活动' },
        ],
        selectLists: {
            zzjx: [
                {
                    _src: 'https://ossweb-img.qq.com/images/clientpop/act/lol_1665201907_uploadnewsImg.jpg',
                    title: '灵魂莲华2022',
                    times: '35天后结束'
                },
                {
                    _src: 'https://ossweb-img.qq.com/images/clientpop/act/lol_1662519754_uploadnewsImg.jpg',
                    title: '巨龙之境 隐秘海域',
                    times: '57天后结束'
                },
                {
                    _src: 'https://ossweb-img.qq.com/images/clientpop/act/lol_1665201843_uploadnewsImg.jpg',
                    title: '龙岛纵横',
                    times: '22天后结束'
                },
                {
                    _src: 'https://ossweb-img.qq.com/images/clientpop/act/lol_1665201742_uploadnewsImg.jpg',
                    title: '神龙尊者 小小艾希',
                    times: '57天后结束'
                }
            ],
            scth: [
                {
                    _src: 'https://ossweb-img.qq.com/images/clientpop/act/lol_1664510668_uploadnewsImg.jpg',
                    title: '2022全球总决赛周边火热开售',
                    times: '27天后结束'
                },
                {
                    _src: 'https://ossweb-img.qq.com/images/clientpop/act/lol_1664265069_uploadnewsImg.jpg',
                    title: '联盟限定福袋 倒计时三天！',
                    times: '已结束'
                },
                {
                    _src: 'https://ossweb-img.qq.com/images/clientpop/act/lol_1660131413_uploadnewsImg.jpg',
                    title: '开天婕拉雕塑 限量预售中',
                    times: '已结束'
                },
                {
                    _src: 'https://ossweb-img.qq.com/images/clientpop/act/lol_1664528346_uploadnewsImg.jpg',
                    title: '金克丝雕塑 限量抢购中',
                    times: '43天后结束'
                }
            ],
            cqhd: [
                {
                    _src: 'https://ossweb-img.qq.com/images/clientpop/act/lol_1662605472_uploadnewsImg.jpg',
                    title: '龙境探秘',
                    times: '长期活动'
                },
                {
                    _src: 'https://ossweb-img.qq.com/images/clientpop/act/lol_1662609186_uploadnewsImg.jpg',
                    title: '小小李青',
                    times: '长期活动'
                },
                {
                    _src: 'https://ossweb-img.qq.com/images/clientpop/act/lol_1662605406_uploadnewsImg.jpg',
                    title: '浪花度假村大礼包',
                    times: '长期活动'
                },
                {
                    _src: 'https://ossweb-img.qq.com/images/clientpop/act/lol_1662605203_uploadnewsImg.jpg',
                    title: '新款巨龙幼崽已孵化',
                    times: '长期活动'
                }
            ]
        },
        types: [
            "在线客服",
            "秩序殿堂",
            "游戏资料",
            "峡谷之巅",
            "云顶之弈",
            "攻略中心",
            "LOL宇宙",
            "微信绑定"
        ],
        HeroUpdate: [
            {
                title: '英雄跟新',
                _src: 'https://img.crawler.qq.com/lolwebschool/0/JAutoCMS_LOLWeb_1d1b4a6d6a2fcc558507c0ba84815148/0',
                names: '首领行者 乌迪尔',
                content: '他踏寒风而来，在平衡与纷争中探寻弗雷尔卓德的未来'
            },
            {
                title: '新皮肤',
                _src: '	https://img.crawler.qq.com/lolwebschool/0/JAutoCMS_LOLWeb_cc4f6693d7dda79fc10a10de888395b0/0',
                names: '灵魂莲华2022',
                content: '一瞬心间 一念绽灵。灵魂莲华系列全新皮肤上线！'
            }
        ],
        zhizhen:[
            'https://img.crawler.qq.com/lolwebschool/0/JAutoCMS_LOLWeb_9804a271570fa09208031d53017d6437/0',
            'https://img.crawler.qq.com/lolwebschool/0/JAutoCMS_LOLWeb_a363944cdcae09cf08ea4be8a6bb3948/0'
        ],
        zeli:{
            _src:'https://img.crawler.qq.com/lolwebschool/0/JAutoCMS_LOLWeb_c218eab6d7d6e52809a8e3f294dc5b35/0',
            title:'周免·'
        }
        

    },
    getters: {}
})