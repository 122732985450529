<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right"  >
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/rencaizhengce' }">人才政策</el-breadcrumb-item>
      <el-breadcrumb-item >查询</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="content">
    <div class="btn" @click="query()">查询</div>
    <div class="view">
      <div class="view_title">人才分类:</div>
      <el-checkbox-group v-model="checkList" style="background-color: #EEEEEE;padding: 10px;border-radius: 10px;">
        <el-checkbox label="海外人才"></el-checkbox>
        <el-checkbox label="顶尖人才"></el-checkbox>
        <el-checkbox label="领军人才"></el-checkbox>
        <el-checkbox label="青年人才"></el-checkbox>
        <el-checkbox label="技能人才"></el-checkbox>
        <el-checkbox label="行业人才"></el-checkbox>
        <el-checkbox label="创业人才"></el-checkbox>
        <el-checkbox label="高端人才"></el-checkbox>
      </el-checkbox-group>
    </div>
    <div class="view">
      <div class="view_title">政策分类:</div>
      <el-checkbox-group v-model="policy" style="background-color: #EEEEEE;padding: 10px;border-radius: 10px;">
        <el-checkbox label="经费补贴"></el-checkbox>
        <el-checkbox label="金融补贴"></el-checkbox>
        <el-checkbox label="薪酬补贴"></el-checkbox>
        <el-checkbox label="生活补贴"></el-checkbox>
        <el-checkbox label="创业扶持"></el-checkbox>
        <el-checkbox label="人才服务"></el-checkbox>
        <el-checkbox label="购房补贴"></el-checkbox>
        <el-checkbox label="就业补贴"></el-checkbox>
        <el-checkbox label="高校补贴"></el-checkbox>
      </el-checkbox-group>
    </div>
  </div></div>

</template>

<script>
import { Server} from "@/server";
export default {
  name: "search",
  data() {
    return {
      checkList: [],//人才的多选
      policy: [],//政策的多选
    };
  },
  methods: {
    query(){
      //处理选中的数据
      var newcheckList = [];
      this.checkList.map((citem, cindex)=>{
        if(citem == '海外人才'){
          newcheckList.push('海外人才')
        }else if(citem == '顶尖人才'){
          newcheckList.push('顶尖人才')
        }else if(citem == '领军人才'){
          newcheckList.push('领军人才')
        }else if(citem == '青年人才'){
          newcheckList.push('青年人才')
        }
        else if(citem == '技能人才'){
          newcheckList.push('技能人才')
        }else if(citem == '行业人才'){
          newcheckList.push('行业人才')
        }else if(citem == '创业人才'){
          newcheckList.push('创业人才')
        }
        else if(citem == '高端人才'){
          newcheckList.push('高端人才')
        }
      })
      newcheckList = newcheckList.join(',')

      var newpolicy = [];
      this.policy.map((ptime, pindex)=>{
        if(ptime == '经费补贴'){
          newpolicy.push('zjzc')
        }else if(ptime == '金融补贴'){
          newpolicy.push('jrzc')
        }else if(ptime == '薪酬补贴'){
          newpolicy.push('xcbt')
        }else if(ptime == '生活补贴'){
          newpolicy.push('shbt')
        }else if(ptime == '创业扶持'){
          newpolicy.push('cyfc')
        }else if(ptime == '人才服务'){
          newpolicy.push('rcfw')
        }else if(ptime == '购房补贴'){
          newpolicy.push('gfbt')
        }else if(ptime == '就业补贴'){
          newpolicy.push('jybt')
        }else if(ptime == '高校补贴'){
          newpolicy.push('gxbt')
        }
      })
      newpolicy = newpolicy.join(',')

      if(newcheckList.length>0 && newpolicy.length>0)
      {
        this.$router.push({path:'/searchlist', query: {newpolicy: newpolicy,newcheckList:newcheckList}})
      }else if(newpolicy.length>0 ){
        this.$router.push({path:'/searchlist', query: {newpolicy: newpolicy}})
      }else{
        this.$router.push({path:'/searchlist', query: {newcheckList: newcheckList}})
      }
    }
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
  .el-breadcrumb{
    margin: 10px 0 10px 0;
  }
  .content{
    padding: 30px 0;
    min-height: 60vh;
    .btn{
      background-color: #027ECB;
      width: 100px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      border-radius: 10px;
      font-size: 20px;
      margin-left: calc(100% - 100px);
    }
    .view{
      // display: flex;
      .view_title{
        margin-right: 20px;
        padding: 20px 0;
        font-size: 20px;
      }
    }
  }
</style>
