<template>
    <div class="page">
        <img class="top-bg" src="../../assets/image/second/four.png" alt="">

        <div class="content">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/home?tab=0' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>成绩公示</el-breadcrumb-item>
            </el-breadcrumb>

            <div class="title">成绩公示</div>
            <ul class="list">
                <li class="item" v-for="item in list" @click="toDetail()" >
                    <div class="notice">{{item.title}}</div>
                    <div class="time">{{item.time}}</div>
                </li>
            </ul>

            <div v-if="total >= 10" style="text-align: center; margin-top: 80px; margin-bottom: 150px;">
                <el-pagination
                    layout="prev, pager, next"
                    :total="total"
                    @current-change="currentPage"
                    @prev-click="shang"
                    @next-click="xia"
                >
                </el-pagination>
            </div>
        </div>
        <!-- <div style="height: 1000px;"></div> -->
    </div>
</template>

<script>

export default{
    name: 'gradeTime',
    data() {
        return {
            total: 6,
            list: [
                // {
                //     title: '淄博市第十七届中学生运动会健美操比赛成绩公示',
                //     time: '2024-1-23'
                // },
                // {
                //     title: "淄博市第十七届中学生运动会跳绳比赛成绩公示",
                //     time: '2024-1-23'
                // },
                // {
                //     title: "淄博市第十七届中学生运动会踢毽子比赛竞赛规程成绩公示",
                //     time: '2024-1-23'
                // },
                // {
                //     title: "淄博市第十七届中学生运动会轮滑比赛成绩公示",
                //     time: '2024-1-23'
                // },
                // {
                //     title: '淄博市第十七届中学生运动会武术比赛暨淄博市首届中学生武术比赛成绩公示',
                //     time: '2024-1-23'
                // },
                // {
                //     title: "淄博市第十七届中学生运动会三人制篮球比赛暨淄博市第五届中小学生三人制篮球比赛成绩公示",
                //     time: '2024-1-23'
                // }
            ]
        };
    },
    methods: {
        currentPage(page) {
            this.pageNo = page;
        },
        xia() {
            this.pageNo = this.pageNo + 1;
        },
        shang() {
            this.pageNo = this.pageNo - 1;
        },

        toDetail() {
            this.$router.push({
                path: '/achievementDetail'
            })
        }
    }
}
</script>

<style lang="less" scoped> 
.page {
    width: 100%;
    position: relative;
    .top-bg {
        width: 100%;
        height: 1000px;
        position: relative;
        top: 0;
        z-index: 0;
    }
    .content {
        width: 70%;
        margin: 0 auto;
        position: relative;
        top: -552px;
        background-color: #fff;
        border-radius: 20px;

        min-height: 500px;
        .title {
            text-align: center;
            margin: 35px;
            margin-bottom: 50px;
            font-size: 22px;
            font-weight: bold;
        }
        .list {
            width: 90%;
            margin: 0 auto;

            .item {
                background-color: #fff;
                padding: 20px 30px;
                margin: 20px 0;
                border: 1px solid #ddd;
                border-radius: 15px;
                cursor: pointer;

                display: flex;
                align-items: center;

                .notice {
                    margin-right: 25px;
                    flex: 1;
                }
            }
        }
        ::v-deep .el-pager li.active {
            background-color: #F24545;
            color: #f5f5f5;
            border-radius: 5px;
        }
        ::v-deep .el-pager li {
            color: #F24545;
            min-width: 30px;
            margin: 0 10px;
            &:nth-child(1) {
                margin-left: 0;
            }
            &:nth-last-child(1) {
                margin-right: 0;
            }
        }
    }
}

@media screen and (max-width: 1199px) and (min-width: 600px) { 
.page {
    width: 100%;
    position: relative;
    .top-bg {
        width: 100%;
        height: 800px;
        position: relative;
        top: 0;
        z-index: 0;
    }
    .content {
        width: 70%;
        margin: 0 auto;
        position: relative;
        top: -450px;
        background-color: #fff;
        border-radius: 20px;
        .title {
            text-align: center;
            margin: 35px;
            margin-bottom: 50px;
            font-size: 22px;
            font-weight: bold;
        }
        .list {
            width: 90%;
            margin: 0 auto;

            .item {
                background-color: #fff;
                padding: 20px 30px;
                margin: 20px 0;
                border: 1px solid #ddd;
                border-radius: 15px;
                cursor: pointer;

                display: flex;
                align-items: center;

                .notice {
                    margin-right: 25px;
                    flex: 1;
                }
            }
        }
        ::v-deep .el-pager li.active {
            background-color: #F24545;
            color: #f5f5f5;
            border-radius: 5px;
        }
        ::v-deep .el-pager li {
            color: #F24545;
            min-width: 30px;
            margin: 0 10px;
            &:nth-child(1) {
                margin-left: 0;
            }
            &:nth-last-child(1) {
                margin-right: 0;
            }
        }
    }
}
}
</style>