import Vue from "vue";
import VueRouter, { RouterView } from "vue-router";
import Main from "../pages/Home/index.vue";
import Notice from "../pages/Home/notice.vue";
import Noticedetail from "../pages/Home/noticedetail.vue";
import greatCompany from "../pages/Home/greatCompany.vue";
import Search from "../pages/Home/search.vue";
import Searchlist from "../pages/Home/searchlist.vue";
import Searchlistdetail from "../pages/Home/searchlistdetail.vue";
import policy from "../pages/Home/policy.vue";
import policyDetail from "../pages/Home/policyDetail.vue";
import qiyefengcai from "@/pages/Home/qiyefengcai.vue";
import qiyefengcaiDetail from "@/pages/Home/qiyefengcaiDetail.vue";
import promote from "@/pages/Home/promote.vue";
import promoteDetail from "@/pages/Home/promoteDetail.vue";
import jingmaoqiatan from "@/pages/Home/jingmaoqiatan.vue";
import jingmaoqiatanDetail from "@/pages/Home/jingmaoqiatanDetail.vue";
import duiwaimaoyi from "@/pages/Home/duiwaimaoyi.vue";
import duiwaimaoyiDetail from "@/pages/Home/duiwaimaoyiDetail.vue";
import zhaoshangtouzi from "@/pages/Home/zhaoshangtouzi.vue";
import zhaoshangtouziDetail from "@/pages/Home/zhaoshangtouziDetail.vue";
import rencaipeixun from "@/pages/Home/rencaipeixun.vue";
import rencaipeixunDetail from "@/pages/Home/rencaipeixunDetail.vue";
import kuajingfuwu from "@/pages/Home/kuajingfuwu.vue";
import kuajingfuwuDetail from "@/pages/Home/kuajingfuwuDetail.vue";
import guojilianluo from "@/pages/Home/guojilianluo.vue";
import guojilianluoDetail from "@/pages/Home/guojilianluoDetail.vue";
import touzicujin from "@/pages/Home/touzicujin.vue";
import touzicujinDetail from "@/pages/Home/touzicujinDetail.vue";
import tongzhigonggao from "@/pages/Home/tongzhigonggao.vue";
import tupianxinwen from "@/pages/Home/tupianxinwen.vue";
import zhuantizhuanlan from "@/pages/Home/zhuantizhuanlan.vue";
import huizhanxinxi from "@/pages/Home/huizhanxinxi.vue";
import noticetwo from "@/pages/Home/noticetwo.vue";
import fengxianfangkong from "@/pages/Home/fengxianfangkong.vue";
import guojijingmao from "@/pages/Home/guojijingmao.vue";
import xiangmuduijie from "@/pages/Home/xiangmuduijie.vue";
import guojihuiketing from "@/pages/Home/guojihuiketing.vue";
import huiyuanzhijia from "@/pages/Home/huiyuanzhijia.vue";
import shangshirenzheng from "@/pages/Home/shangshirenzheng.vue";
import xiangmutuiguang from "@/pages/Home/xiangmutuiguang.vue";
import jiandangzhuanlan from "@/pages/Home/jiandangzhuanlan.vue";
import policytwo from "@/pages/Home/policytwo.vue";
import guojilianluotwo from "@/pages/Home/guojilianluotwo.vue";
import jingmaoqiatantwo from "@/pages/Home/jingmaoqiatantwo";
import duiwaimaoyitwo from "@/pages/Home/duiwaimaoyitwo.vue";
import kuajingfuwutwo from "@/pages/Home/kuajingfuwutwo.vue";
import touzicujintwo from "@/pages/Home/touzicujintwo.vue";
import zhaoshangtouzitwo from "@/pages/Home/zhaoshangtouzitwo.vue";
import shangshifuwu from '@/pages/Home/shangshifuwu.vue';
import huizhanxinxiDetail from "@/pages/Home/huizhanxinxiDetail.vue";
import huizhanxinxiList from "@/pages/Home/huizhanxinxiList.vue";
import shangfafuwu from "@/pages/Home/shangfafuwu.vue";
import shangfafuwuDetail from "@/pages/Home/shangfafuwuDetail.vue";

import gameNotice from '@/pages/Home/gameNotice.vue'
import gameNoticeDetail from '@/pages/Home/gameNoticeDetail.vue'

import gradeTime from '@/pages/Home/gradeTime.vue'
import gradeTimeDetail from '@/pages/Home/gradeTimeDetail.vue'

import culture from '@/pages/Home/culture.vue'
import cultureDetail from '@/pages/Home/cultureDetail.vue'

import achievement from '@/pages/Home/achievement.vue'
import achievementDetail from '@/pages/Home/achievementDetail.vue'

import guanfangzixun from '@/pages/Home/guanfangzixun.vue'
import jingcaizhongyunhui from '@/pages/Home/jingcaizhongyunhui.vue'

import publicReport from "@/pages/Home/publicReport.vue";
import publicReportDetail from "@/pages/Home/publicReportDetail.vue";
import mediaReport from '@/pages/Home/mediaReport.vue'
import mediaReportDetail from '@/pages/Home/mediaReportDetail'


import hometwo from "@/pages/Home/hometwo.vue";

// const hometwo2 = () => import("../pages/Home/hometwo.vue")
Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "Home",
            component: Main,
            children: [],
        },
        {
            path: "/home",
            name: "Home",
            component: Main,
            children: [],
        },
        {
            path: "/notice",
            name: "Notice",
            component: Notice,
            children: [],
        },
        {
            path: "/noticedetail",
            name: "Noticedetail",
            component: Noticedetail,
            children: [],
        },
        {
            path: "/search",
            name: "Search",
            component: Search,
            children: [],
        },
        {
            path: "/searchlist",
            name: "Searchlist",
            component: Searchlist,
            children: [],
        },
        {
            path: "/searchlistdetail",
            name: "Searchlistdetail",
            component: Searchlistdetail,
            children: [],
        },

        {
            path: "/hometwo",
            name: "hometwo",
            component: hometwo,
            children: [],
        },
        {
            path: "/greatCompany",
            name: "greatCompany",
            component: greatCompany,
            children: [],
        },
        {
            path: "/policy",
            name: "policy",
            component: policy,
            children: [],
        },
        {
            path: "/policyDetail",
            name: "policyDetail",
            component: policyDetail,
            children: [],
        },
        {
            path: "/qiyefengcai",
            name: "qiyefengcai",
            component: qiyefengcai,
            children: [],
        },
        {
            path: "/qiyefengcaiDetail",
            name: "qiyefengcaiDetail",
            component: qiyefengcaiDetail,
            children: [],
        },
        {
            path: "/promote",
            name: "promote",
            component: promote,
            children: [],
        },
        {
            path: "/promoteDetail",
            name: "promoteDetail",
            component: promoteDetail,
            children: [],
        },
        {
            path: "/jingmaoqiatan",
            name: "jingmaoqiatan",
            component: jingmaoqiatan,
            children: [],
        },
        {
            path: "/jingmaoqiatanDetail",
            name: "jingmaoqiatanDetail",
            component: jingmaoqiatanDetail,
            children: [],
        },
        {
            path: "/duiwaimaoyi",
            name: "duiwaimaoyi",
            component: duiwaimaoyi,
            children: [],
        },
        {
            path: "/duiwaimaoyiDetail",
            name: "duiwaimaoyiDetail",
            component: duiwaimaoyiDetail,
            children: [],
        },
        {
            path: "/zhaoshangtouzi",
            name: "zhaoshangtouzi",
            component: zhaoshangtouzi,
            children: [],
        },
        {
            path: "/zhaoshangtouziDetail",
            name: "zhaoshangtouziDetail",
            component: zhaoshangtouziDetail,
            children: [],
        },
        {
            path: '/shangshifuwu',
            name: 'shangshifuwu',
            component: shangshifuwu,
        },
        {
            path: "/rencaipeixun",
            name: "rencaipeixun",
            component: rencaipeixun,
            children: [],
        },
        {
            path: "/rencaipeixunDetail",
            name: "rencaipeixunDetail",
            component: rencaipeixunDetail,
            children: [],
        },
        {
            path: "/kuajingfuwu",
            name: "kuajingfuwu",
            component: kuajingfuwu,
            children: [],
        },
        {
            path: "/kuajingfuwuDetail",
            name: "kuajingfuwuDetail",
            component: kuajingfuwuDetail,
            children: [],
        },
        {
            path: "/guojilianluo",
            name: "guojilianluo",
            component: guojilianluo,
            children: [],
        },
        {
            path: "/guojilianluoDetail",
            name: "guojilianluoDetail",
            component: guojilianluoDetail,
            children: [],
        },
        {
            path: "/touzicujin",
            name: "touzicujin",
            component: touzicujin,
            children: [],
        },
        {
            path: "/touzicujinDetail",
            name: "touzicujinDetail",
            component: touzicujinDetail,
            children: [],
        },
        {
            path: "/tongzhigonggao",
            name: "tongzhigonggao",
            component: tongzhigonggao,
            children: [],
        },
        {
            path: "/tupianxinwen",
            name: "tupianxinwen",
            component: tupianxinwen,
            children: [],
        },
        {
            path: "/zhuantizhuanlan",
            name: "zhuantizhuanlan",
            component: zhuantizhuanlan,
            children: [],
        },
        {
            path: "/huizhanxinxi",
            name: "huizhanxinxi",
            component: huizhanxinxi,
            children: [],
        },
        {
            path: "/noticetwo",
            name: "noticetwo",
            component: noticetwo,
            children: [],
        },
        {
            path: "/fengxianfangkong",
            name: "fengxianfangkong",
            component: fengxianfangkong,
            children: [],
        },
        {
            path: "/guojijingmao",
            name: "guojijingmao",
            component: guojijingmao,
            children: [],
        },
        {
            path: "/xiangmuduijie",
            name: "xiangmuduijie",
            component: xiangmuduijie,
            children: [],
        },
        {
            path: "/guojihuiketing",
            name: "guojihuiketing",
            component: guojihuiketing,
            children: [],
        },
        {
            path: "/huiyuanzhijia",
            name: "huiyuanzhijia",
            component: huiyuanzhijia,
            children: [],
        },
        {
            path: "/shangshirenzheng",
            name: "shangshirenzheng",
            component: shangshirenzheng,
            children: [],
        },
        {
            path: "/xiangmutuiguang",
            name: "xiangmutuiguang",
            component: xiangmutuiguang,
            children: [],
        },
        {
            path: "/jiandangzhuanlan",
            name: "jiandangzhuanlan",
            component: jiandangzhuanlan,
            children: [],
        },
        {
            path: "/policytwo",
            name: "policytwo",
            component: policytwo,
            children: [],
        },
        {
            path: "/guojilianluotwo",
            name: "guojilianluotwo",
            component: guojilianluotwo,
            children: [],
        },
        {
            path: "/jingmaoqiatantwo",
            name: "jingmaoqiatantwo",
            component: jingmaoqiatantwo,
            children: [],
        },
        {
            path: "/duiwaimaoyitwo",
            name: "duiwaimaoyitwo",
            component: duiwaimaoyitwo,
            children: [],
        },
        {
            path: "/kuajingfuwutwo",
            name: "kuajingfuwutwo",
            component: kuajingfuwutwo,
            children: [],
        },
        {
            path: "/touzicujintwo",
            name: "touzicujintwo",
            component: touzicujintwo,
            children: [],
        },
        {
            path: "/zhaoshangtouzitwo",
            name: "zhaoshangtouzitwo",
            component: zhaoshangtouzitwo,
            children: [],
        },
        {
            path: "/huizhanxinxiDetail",
            name: "huizhanxinxiDetail",
            component: huizhanxinxiDetail,
            children: [],
        },
        {
            path: "/huizhanxinxiList",
            name: "huizhanxinxiList",
            component: huizhanxinxiList,
            children: [],
        },
        {
            path: "/shangfafuwu",
            name: "shangfafuwu",
            component: shangfafuwu,
            children: [],
        },
        {
            path: "/shangfafuwuDetail",
            name: "shangfafuwuDetail",
            component: shangfafuwuDetail,
            children: [],
        },


        {
            path: '/gameNotice',
            name: 'gameNotice',
            component: gameNotice,
            children: []
        },
        {
            path: '/gameNoticeDetail',
            name: 'gameNoticeDetail',
            component: gameNoticeDetail,
            children: []
        },
        {
            path: '/guanfangzixun',
            name: 'guanfangzixun',
            component: guanfangzixun,
            children: []
        },
        {
            path: '/jingcaizhongyunhui',
            name: 'jingcaizhongyunhui',
            component: jingcaizhongyunhui,
            children: []
        },

        {
            path: '/publicReport',
            name: 'publicReport',
            component: publicReport,
            children: []
        },
        {
            path: '/publicReportDetail',
            name: 'publicReportDetail',
            component: publicReportDetail,
            children: []
        },

        {
            path: '/mediaReport',
            name: 'mediaReport',
            component: mediaReport,
            children: []
        },
        {
            path: '/mediaReportDetail',
            name: 'mediaReportDetail',
            component: mediaReportDetail,
            children: []
        },

        {
            path: '/gradeTime',
            name: 'gradeTime',
            component: gradeTime,
            children: []
        },
        {
            path: '/gradeTimeDetail',
            name: 'gradeTimeDetail',
            component: gradeTimeDetail,
            children: []
        },

        {
            path: '/culture',
            name: 'culture',
            component: culture,
            children: []
        },
        {
            path: '/cultureDetail',
            name: 'cultureDetail',
            component: cultureDetail,
            children: []
        },

        {
            path: '/achievement',
            name: 'achievement',
            component: achievement,
            children: []
        },
        {
            path: '/achievementDetail',
            name: 'achievementDetail',
            component: achievementDetail,
            children: []
        },
    ],
});

const originnalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    // console.log(location);   // 路由的name和path
    return originnalPush.call(this, location).catch((err) => err);
};
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
});
export default router;

// export default new router({
//     mode: 'history',
//     base: process.env.BASE_URL,
//     routes: [
//     // 首页
//     {
//         path: '/home',
//         component: () => import('@/views/modules/home/Main.vue'),
//     },
//     // 关于我
//     {
//         path: '/About',
//         component: () => import('@/views/modules/about/Main.vue'),
//     },
//     ],

// })
