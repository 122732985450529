<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item
                :to="{
                    path: toPath,
                    query: {
                        type: type,
                    },
                }"
                >{{ toCTitle }}</el-breadcrumb-item
            >
            <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="content">
            <div class="title">{{ list.length == 0 ? "" : list.title }}</div>
            <div class="fb">
                发布时间：{{
                    list.releaseTime
                }}&nbsp;&nbsp;浏览量：{{ list.pageView }}
            </div>
            <!-- <div class="time">{{list[0].createTime}}</div> -->
            <div
                v-html="list.content"
                style="line-height: 1.6"
            ></div>
            <div
                v-for="(item, index) in itemFiles"
                :key="index"
                @click="download(item.url)"
                class="file"
            >
                附件：{{ item.name }}
            </div>
        </div>
    </div>
</template>

<script>
import { Server } from "@/server";

export default {
    name: "duiwaimaoyitwo",
    data() {
        return {
            type: '',
            id: "",
            index: 0,
            toTitle: "",
            toPath: "",
            toCTitle: "",
            // messageTab: 21,
            pageSize: 10,
            pageNo: 1,
            total: 0,
            list: [], //列表
            itemFiles: [], // 附件列表
        };
    },
    watch: {
        pageNo() {
            this.getdwmy();
        },
    },
    methods: {
        currentPage(page) {
            this.pageNo = page;
        },
        xia() {
            this.pageNo = this.pageNo + 1;
        },
        shang() {
            this.pageNo = this.pageNo - 1;
        },
        getdwmy() {
            console.log(this.type);
            Server.viewCountList({
                type: this.type,
                id: this.id,
                index: this.index,
                status: 1,
                pageSize: this.pageSize,
                pageNo: this.pageNo,
            }).then((res) => {
                console.log(res);
                if (res.code == 200) {
                    this.list = res.result;
                    this.handle();
                    this.total = res.result.total;
                }
            });
        },
        handle() {
            console.log(123);
            if (this.list.files != null) {
                this.itemFiles = [];
                var fileList = this.list.files.split(",");
                fileList.forEach((item) => {
                    var filesplit = item.split("/");
                    console.log(filesplit);
                    this.itemFiles.push({
                        name: filesplit[1],
                        url: item,
                    });
                    // console.log(this.itemFiles);
                });
            }
        },
    },
    mounted() {
        console.log(this.$route.query.type);
        this.type = this.$route.query.type;
        this.id = this.$route.query.id;
        this.toPath = this.$route.query.toPath;
        this.toTitle = this.$route.query.toTitle;
        this.toCTitle = this.$route.query.toCTitle;
        this.getdwmy();
        // this.$store.state.CommonHeader.tab = 11
    },
};
</script>

<style lang="less" scoped>
.el-breadcrumb {
    padding: 20px 0 10px 10px;
}
.content {
    background-color: #ffffff;
    padding: 30px 10px 30px 10px;
    margin: 0 0 10px 0;
    line-height: 2;
    .file {
        color: blue;
        padding: 10px;
    }
    .time {
        font-size: 18rpx;
        color: #999999;
        margin: 20px 0;
        text-align: right;
    }
    .title {
        font-size: 24px;
        text-align: center;
        margin-bottom: 20px;
    }
    .fb {
        background-color: #f5f5f5;
        font-size: 14px;
        text-align: center;
        height: 35px;
        line-height: 35px;
        margin-bottom: 10px;
    }
}
</style>
