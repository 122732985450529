<template>
    <div class="page">
        <img class="top-bg" src="../../assets/image/second/two.png" alt="">

        <div class="content">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/jingcaizhongyunhui?activeName=1' }">精彩中运会</el-breadcrumb-item>
                <el-breadcrumb-item>赛事文化</el-breadcrumb-item>
            </el-breadcrumb>

            <div class="title">赛事文化</div>
            <ul class="list">
                <li class="item" v-for="(item, index) in list" :key="index" @click="toDetail()" >
                    <img class="item-img" :src="downloadUrl + item.coverpicture" alt="">
                    <div class="right">
                        <div class="subtitle">{{item.title}}</div>
                        <div class="desc">
                            <div class="contents" v-html="item.content"></div>
                        </div>
                        <div class="time">{{item.createTime.split(' ')[0]}}</div>
                    </div>
                </li>
                
            </ul>

            <div style="text-align: center; margin-top: 80px; margin-bottom: 150px;">
                <el-pagination
                    layout="prev, pager, next"
                    :total="total"
                    @current-change="currentPage"
                    @prev-click="shang"
                    @next-click="xia"
                >
                </el-pagination>
            </div>
        </div>
        <div class="foot"></div>
    </div>
</template>

<script>
import { Server } from '@/server';
export default{
    name: 'culture',
    data() {
        return {
            total: 0,

            list: [],

            downloadUrl: '',
        };
    },
    methods: {
        currentPage(page) {
            this.pageNo = page;
        },
        xia() {
            this.pageNo = this.pageNo + 1;
        },
        shang() {
            this.pageNo = this.pageNo - 1;
        },
        toDetail() {
            this.$router.push({
                path: '/cultureDetail'
            })
        },

        getData() {
            Server.infoList({xcOrTz: 1, publicityType: 4}).then(res => {
                console.log(res);
                if(res.code == 200) {
                    this.list = res.result.records
                    this.total = res.result.total
                }
            })
        }
    },
    mounted() {
        this.getData()

        this.downloadUrl = this.$downloadUrl
    }
}
</script>

<style lang="less" scoped> 
.page {
    width: 100%;
    position: relative;
    .top-bg {
        width: 100%;
        height: 900px;
        position: absolute;
        top: 0;
        z-index: 0;
    }
    .content {
        width: 70%;
        margin: 0 auto 50px;
        position: relative;
        top: 450px;
        background-color: #fff;
        border-radius: 20px;
        .contents {
            ::v-deep p {
                &:nth-child(1) {
                    margin: 0 !important;
                }
            }  
        } 
        .title {
            text-align: center;
            margin: 25px;
            font-size: 22px;
            font-weight: bold;
        }
        .list {
            width: 90%;
            margin: 0 auto;

            .item {
                background-color: #fff;
                padding: 20px 0;
                border-bottom: 1px solid #eee;
                cursor: pointer;

                display: flex;
                align-items: center;

                .item-img {
                    width: 220px;
                    height: 125px;
                    margin: 0 20px;
                    background-color: #f0f0f0;
                }
                .right {
                    flex: 1;
                    height: 120px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .subtitle {
                        font-size: 22px;
                        font-weight: bold;
                    }
                    .desc {
                        color: #777;
                        // text-indent: 2.2em;
                        line-height: 20px;

                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                    .time {
                        text-align: right;
                        color: #666;
                        font-size: 14px;
                    }
                }
            }
        }
        ::v-deep .el-pager li.active {
            background-color: #F24545;
            color: #f5f5f5;
            border-radius: 5px;
        }
        ::v-deep .el-pager li {
            color: #F24545;
            min-width: 30px;
            margin: 0 10px;
            &:nth-child(1) {
                margin-left: 0;
            }
            &:nth-last-child(1) {
                margin-right: 0;
            }
        }
        ::v-deep .el-pagination {
            padding-bottom: 30px
        }
    }
    .foot {
        height: 450px;
    }
}

@media screen and (max-width: 1199px) and (min-width: 600px) {
.page {
    width: 100%;
    position: relative;
    .top-bg {
        width: 100%;
        height: 800px;
        position: absolute;
        top: 0;
        z-index: 0;
    }
    .content {
        width: 80%;
        margin: 0 auto;
        position: relative;
        top: 350px;
        background-color: #fff;
        border-radius: 20px;
        .title {
            text-align: center;
            margin: 25px;
            font-size: 22px;
            font-weight: bold;
        }
        .list {
            width: 90%;
            margin: 0 auto;

            .item {
                background-color: #fff;
                padding: 20px 0;
                border-bottom: 1px solid #eee;
                cursor: pointer;

                display: flex;
                align-items: center;

                .item-img {
                    width: 200px;
                    height: 125px;
                    margin: 0 20px;
                    background-color: #f0f0f0;
                }
                .right {
                    flex: 1;
                    height: 120px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .subtitle {
                        font-size: 22px;
                        font-weight: bold;
                    }
                    .desc {
                        color: #777;
                        // text-indent: 2.2em;
                        line-height: 20px;

                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                    .time {
                        text-align: right;
                        color: #666;
                        font-size: 14px;
                    }
                }
            }
        }
        ::v-deep .el-pager li.active {
            background-color: #F24545;
            color: #f5f5f5;
            border-radius: 5px;
        }
        ::v-deep .el-pager li {
            color: #F24545;
            min-width: 30px;
            margin: 0 10px;
            &:nth-child(1) {
                margin-left: 0;
            }
            &:nth-last-child(1) {
                margin-right: 0;
            }
        }
    }
    .foot {
        height: 350px;
    }
}
}
</style>