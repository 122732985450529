<template>
    <div class="content">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <!-- <el-breadcrumb-item v-if="tab == 1">信息公开</el-breadcrumb-item> -->
            <div v-if="isShow">
                <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
            </div>
            <div v-else>
                <el-breadcrumb-item
                v-if="tab == 1"
                @click.native="breadClick(list[8])"
                >国际经贸促进</el-breadcrumb-item
                >
                <el-breadcrumb-item
                    v-if="tab == 2"
                    @click.native="breadClick(list[7])"
                    >国际联络</el-breadcrumb-item
                >
                <el-breadcrumb-item v-if="tab == 3" @click="breadClick(list[5])"
                    >会展服务</el-breadcrumb-item
                >
                <el-breadcrumb-item v-if="tab == 4" @click="breadClick(list[19])"
                    >投资促进</el-breadcrumb-item
                >
                <el-breadcrumb-item v-if="tab == 5" @click="breadClick(list[6])"
                    >商事法律</el-breadcrumb-item
                >
                <el-breadcrumb-item v-if="tab == 6" @click="breadClick(list[12])"
                    >会员之家</el-breadcrumb-item
                >
            </div>
            <!-- <el-breadcrumb-item v-if="tab == 7" @click="breadClick(item)"
                >关于我们</el-breadcrumb-item
            > -->
        </el-breadcrumb>
        <div class="content-box">
            <div class="content-left">
                <div class="left-top">中国国际贸易促进委员会淄博市委员会</div>
                <ul
                    class="left-bottom"
                    v-for="(item, index) in list"
                    :key="index"
                >
                    <li @click="tabname(item)">
                        <div class="tab">
                            {{ item.text }}
                        </div>
                        <div class="jiantou">
                            <svg
                                t="1685284252456"
                                class="icon"
                                viewBox="0 0 1024 1024"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                p-id="4958"
                                width="20"
                                height="20"
                            >
                                <path
                                    d="M666.7 505.5l-246-178c-5.3-3.8-12.7-0.1-12.7 6.5v46.9c0 10.2 4.9 19.9 13.2 25.9L566.6 512 421.2 617.2c-8.3 6-13.2 15.6-13.2 25.9V690c0 6.5 7.4 10.3 12.7 6.5l246-178c4.4-3.2 4.4-9.8 0-13z"
                                    fill="#bfbfbf"
                                    p-id="4959"
                                ></path>
                                <path
                                    d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                                    fill="#bfbfbf"
                                    p-id="4960"
                                ></path>
                            </svg>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="content-right" style="position: relative;">
                <div>
                    <div class="top-title">{{ title }}</div>
                </div>
                <div class="right-bottom">
                    <div
                        v-for="(item, index) in contentList"
                        :key="index"
                        class="item"
                        @click="detail(item)"
                    >
                        <div class="title">
                            <span style="color: blue; margin-right: 10px"
                                >[{{ item.releaseTime.split(" ")[0] }}]</span
                            >
                            <span>{{ item.title }}</span>
                        </div>
                    </div>
                    <div style="text-align: center; margin-top: 20px">
                        <!-- <el-pagination
                            layout="prev, pager, next"
                            :total="total"
                            @current-change="currentPage"
                            @prev-click="shang"
                            @next-click="xia"
                        >
                        </el-pagination> -->
                    </div>

                    
                </div>
                <div style="position: absolute; bottom: 50px; left: 50%; transform: translateX(-50%);">
                        <el-pagination
                            layout="prev, pager, next"
                            :total="total"
                            @current-change="currentPage"
                            @prev-click="shang"
                            @next-click="xia"
                        >
                        </el-pagination>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Server } from "@/server";

export default {
    data() {
        return {
            tab: this.$store.state.CommonHeader.tab,
            tabList: [],
            list: [], // 左侧标题的数组
            pageSize: 10,
            pageNo: 1,
            title: "",
            type: 1,
            mTitle: "",
            contentList: [], // 内容的数组


            isShow: false, // 判断首页后的标题用哪一个数组的内容

            total: 0, // 共多少条数据
        };
    },
    watch: {
        // pageNo() {
        //     this.tabClick();
        // },
        $route(oldVal, newVal) {
            this.tab = this.$store.state.CommonHeader.tab;
            this.title = this.tabList[this.tab - 1].title;
            this.mTitle = this.title;
            this.type = this.tabList[this.tab - 1].type;
            this.getcontentList();
        },
        pageNo() {
            this.getcontentList();
        },
    },
    mounted() {
        console.log(this.isShow)
        this.tab = this.$store.state.CommonHeader.tab;
        this.type = this.$store.state.CommonHeader.type;
        console.log(this.tab)
        if (this.tab == 0) {
            this.tab = this.$route.query.tab;
        }
        this.small_type = this.$route.query.type;
        console.log(this.small_type)
        this.get();
        // this.tabname();
        // this.breadClick(item);
        
        console.log(this.type);
        console.log(this.tab);
        if(this.tab!=0 && this.tab!=undefined) {
            // this.isShow = false
            this.title = this.tabList[this.tab - 1].title;
            this.mTitle = this.title;
        }
        if(this.small_type) {
            this.isShow = true
            this.title = this.list[this.small_type - 1].text
            this.type = this.small_type
            this.getcontentList();
        }
        
    },
    // beforeUpdate() {
    //     console.log(this.isShow)
    //     this.isShow = false
    // },  

    methods: {
        get() {
            this.list = [
                {
                    text: "贸促新闻",
                    type: 1,
                },
                {
                    text: "通知公告",
                    type: 2,
                },
                {
                    text: "图片新闻",
                    type: 3,
                },
                {
                    text: "专题专栏",
                    type: 4,
                },
                {
                    text: "会展信息",
                    type: 5,
                },
                {
                    text: "政策法规",
                    type: 6,
                },
                {
                    text: "国际联络",
                    type: 7,
                },
                {
                    text: "风险防控",
                    type: 8,
                },
                {
                    text: "国际经贸促进",
                    type: 9,
                },
                {
                    text: "项目对接",
                    type: 10,
                },
                {
                    text: "国际会客厅",
                    type: 11,
                },
                {
                    text: "会员之家",
                    type: 12,
                },
                {
                    text: "经贸洽谈",
                    type: 13,
                },
                {
                    text: "对外贸易",
                    type: 14,
                },
                {
                    text: "跨境服务",
                    type: 15,
                },
                {
                    text: "商事认证",
                    type: 16,
                },
                {
                    text: "项目推广",
                    type: 17,
                },
                {
                    text: "招商引资",
                    type: 18,
                },
                {
                    text: "投资促进",
                    type: 19,
                },
                {
                    text: "党务公开",
                    type: 20,
                },
            ];
            this.tabList = [
                {
                    type: 9,
                    tab: 1,
                    title: "国际经贸促进",
                },
                {
                    type: 7,
                    tab: 2,
                    title: "国际联络",
                },
                {
                    type: 5,
                    tab: 3,
                    title: "会展服务",
                },
                {
                    type: 19,
                    tab: 4,
                    title: "投资促进",
                },
                {
                    type: 16,
                    tab: 5,
                    title: "商事法律",
                },
                {
                    type: 12,
                    tab: 6,
                    title: "会员之家",
                },
                // {
                //     tab: 7,
                //     // tab: 7,
                //     title: "关于我们",
                // },
            ];
        },
        breadClick(item) {
            console.log(item);
            this.isShow = false
            this.title = item.title;
            this.type = item.type;
            this.tab = item.tab
            console.log(this.title, this.type)
            this.getcontentList();
        },
        tabname(item) {
            this.isShow = true
            this.title = item.text;
            this.type = item.type;
            console.log(this.title)
            this.getcontentList();
        },
        getcontentList() {
            console.log(this.type);
            if(this.type) {
                Server.infoList({
                    type: this.type,
                    status: 1,
                    pageSize: this.pageSize,
                    pageNo: this.pageNo,
                }).then((res) => {
                    if (res.code == 200) {
                        console.log(res.result.records)
                        this.total = res.result.total
                        // this.contentList = Array(res.result.records).reverse();
                        this.contentList = res.result.records;
                    }
                    
                    // this.contentList.sort((a, b) => {
                    //     return a.releaseTime < b.releaseTime ? -1 : 1
                    // })
                    console.log(this.contentList)
                    

                });
            }
            
        },
        detail(item) {
            if (item.type == 1) {
                this.$router.push({
                    path: "/noticetwo",
                    query: {
                        type: item.type,
                        id: item.id,
                        toPath: "/hometwo",
                        toTitle: this.mTitle,
                        toCTitle: this.title,
                    },
                });
            }
            if (item.type == 2) {
                this.$router.push({
                    path: "/tongzhigonggao",
                    query: {
                        type: item.type,
                        id: item.id,
                        toPath: "/hometwo",
                        toTitle: this.mTitle,
                        toCTitle: this.title,
                    },
                });
            }
            if (item.type == 3) {
                this.$router.push({
                    path: "/tupianxinwen",
                    query: {
                        type: item.type,
                        id: item.id,
                        toPath: "/hometwo",
                        toTitle: this.mTitle,
                        toCTitle: this.title,
                    },
                });
            }
            if (item.type == 4) {
                this.$router.push({
                    path: "/zhuantizhuanlan",
                    query: {
                        type: item.type,
                        id: item.id,
                        toPath: "/hometwo",
                        toTitle: this.mTitle,
                        toCTitle: this.title,
                    },
                });
            }
            if (item.type == 5) {
                this.$router.push({
                    path: "/huizhanxinxi",
                    query: {
                        type: item.type,
                        id: item.id,
                        toPath: "/hometwo",
                        toTitle: this.mTitle,
                        toCTitle: this.title,
                    },
                });
            }
            if (item.type == 6) {
                this.$router.push({
                    path: "/policytwo",
                    query: {
                        type: item.type,
                        id: item.id,
                        toPath: "/hometwo",
                        toTitle: this.mTitle,
                        toCTitle: this.title,
                    },
                });
            }
            if (item.type == 7) {
                this.$router.push({
                    path: "/guojilianluotwo",
                    query: {
                        type: item.type,
                        id: item.id,
                        toPath: "/hometwo",
                        toTitle: this.mTitle,
                        toCTitle: this.title,
                    },
                });
            }
            if (item.type == 8) {
                this.$router.push({
                    path: "/fengxianfangkong",
                    query: {
                        type: item.type,
                        id: item.id,
                        toPath: "/hometwo",
                        toTitle: this.mTitle,
                        toCTitle: this.title,
                    },
                });
            }
            if (item.type == 9) {
                this.$router.push({
                    path: "/guojijingmao",
                    query: {
                        type: item.type,
                        id: item.id,
                        toPath: "/hometwo",
                        toTitle: this.mTitle,
                        toCTitle: this.title,
                    },
                });
            }
            if (item.type == 10) {
                this.$router.push({
                    path: "/xiangmuduijie",
                    query: {
                        type: item.type,
                        id: item.id,
                        toPath: "/hometwo",
                        toTitle: this.mTitle,
                        toCTitle: this.title,
                    },
                });
            }
            if (item.type == 11) {
                this.$router.push({
                    path: "/guojihuiketing",
                    query: {
                        type: item.type,
                        id: item.id,
                        toPath: "/hometwo",
                        toTitle: this.mTitle,
                        toCTitle: this.title,
                    },
                });
            }
            if (item.type == 12) {
                this.$router.push({
                    path: "/huiyuanzhijia",
                    query: {
                        type: item.type,
                        id: item.id,
                        toPath: "/hometwo",
                        toTitle: this.mTitle,
                        toCTitle: this.title,
                    },
                });
            }
            if (item.type == 13) {
                this.$router.push({
                    path: "/jingmaoqiatantwo",
                    query: {
                        type: item.type,
                        id: item.id,
                        toPath: "/hometwo",
                        toTitle: this.mTitle,
                        toCTitle: this.title,
                    },
                });
            }
            if (item.type == 14) {
                this.$router.push({
                    path: "/duiwaimaoyitwo",
                    query: {
                        type: item.type,
                        id: item.id,
                        toPath: "/hometwo",
                        toTitle: this.mTitle,
                        toCTitle: this.title,
                    },
                });
            }
            if (item.type == 15) {
                this.$router.push({
                    path: "/kuajingfuwutwo",
                    query: {
                        type: item.type,
                        id: item.id,
                        toPath: "/hometwo",
                        toTitle: this.mTitle,
                        toCTitle: this.title,
                    },
                });
            }
            if (item.type == 16) {
                this.$router.push({
                    path: "/shangshirenzheng",
                    query: {
                        type: item.type,
                        id: item.id,
                        toPath: "/hometwo",
                        toTitle: this.mTitle,
                        toCTitle: this.title,
                    },
                });
            }
            if (item.type == 17) {
                this.$router.push({
                    path: "/xiangmutuiguang",
                    query: {
                        type: item.type,
                        id: item.id,
                        toPath: "/hometwo",
                        toTitle: this.mTitle,
                        toCTitle: this.title,
                    },
                });
            }
            if (item.type == 18) {
                this.$router.push({
                    path: "/zhaoshangtouzitwo",
                    query: {
                        type: item.type,
                        id: item.id,
                        toPath: "/hometwo",
                        toTitle: this.mTitle,
                        toCTitle: this.title,
                    },
                });
            }
            if (item.type == 19) {
                this.$router.push({
                    path: "/touzicujintwo",
                    query: {
                        type: item.type,
                        id: item.id,
                        toPath: "/hometwo",
                        toTitle: this.mTitle,
                        toCTitle: this.title,
                    },
                });
            }
            if (item.type == 20) {
                this.$router.push({
                    path: "/jiandangzhuanlan",
                    query: {
                        type: item.type,
                        id: item.id,
                        toPath: "/hometwo",
                        toTitle: this.mTitle,
                        toCTitle: this.title,
                    },
                });
            }
        },


        currentPage(page) {
            this.pageNo = page;
        },
        xia() {
            this.pageNo = this.pageNo + 1;
        },
        shang() {
            this.pageNo = this.pageNo - 1;
        },
    },
};
</script>

<style lang="less">
.el-breadcrumb {
    padding: 20px 0 10px 10px;
}
.content-box {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    .content-left {
        width: 280px;
        background-color: #eaeaea;
        border-bottom: 1px solid #dcdcdc;
        .left-top {
            width: 100%;
            // padding: 0 10px;
            height: 52px;
            line-height: 52px;
            color: #0e6ce0;
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            background: #fff;
            border-bottom: 2px solid #0e6ce0;
        }
        .left-bottom {
            width: 100%;
            li {
                width: 240px;
                height: 47px;
                margin: auto;
                line-height: 47px;
                border-bottom: 1px solid #fff;
                font-size: 15px;
                padding-right: 10px;
                display: flex;
                justify-content: space-between;
                cursor: pointer;
            }
        }
    }
    .content-right {
        width: 790px;
        background-color: #f6f6f6;
        .top-title {
            width: 100%;
            height: 52px;
            line-height: 52px;
            color: #0e6ce0;
            font-size: 16px;
            font-weight: bold;
            padding-left: 20px;
            padding-right: 35px;
            background: #fff;
            border-bottom: 1px solid #dcdcdc;
            // border-bottom: 2px solid #0e6ce0;
        }
        .right-bottom {
            padding: 15px 0;
            .item {
                padding: 20px;
                background-color: #cccccc;
                cursor: pointer;
                .title {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    -o-text-overflow: ellipsis;
                    font-size: 20px;
                }
            }
            .item:nth-child(2n) {
                background-color: #ffffff;
            }
            .item:hover {
                opacity: 0.8;
            }
        }
    }
}
</style>
