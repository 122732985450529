<template>
    <div class="page">
        <img class="top-bg" src="../../assets/image/second/one.png" alt="">
        <img class="bottom-bg" src="../../assets/image/bottomBg.png" alt="">
        <div class="content">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/home?tab=0' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/guanfangzixun' }">官方资讯</el-breadcrumb-item>

                <el-breadcrumb-item>赛事通知</el-breadcrumb-item>
            </el-breadcrumb>

            <div class="title">赛事通知</div>
            <ul class="list">
                <li class="item" v-for="(item, index) in list" :key="index" @click="toDetail(item)">
                    <div class="left">
                        <!-- <div class="num">{{item.num}}</div> -->
                        <div class="time">{{item.createTime.split(' ')[0]}}</div>
                    </div>
                    <div class="right">{{item.title}}</div>
                </li>
            </ul>

            <div style="text-align: center; margin-top: 80px">
                <el-pagination
                    layout="prev, pager, next"
                    :total="total"
                    @current-change="currentPage"
                    @prev-click="shang"
                    @next-click="xia"
                >
                </el-pagination>
            </div>
        </div>

    </div>
</template>

<script>
import { Server } from "@/server";
    export default{
        name: 'gameNotice',
        data() {
            return {
                total: 0,
                list: [],
            };
        },
        methods: {
            currentPage(page) {
                this.pageNo = page;
            },
            xia() {
                this.pageNo = this.pageNo + 1;
            },
            shang() {
                this.pageNo = this.pageNo - 1;
            },

            toDetail(item) {
                this.$router.push({
                    path: '/gameNoticeDetail',
                    query: {
                        id: item.id
                    }
                })
            },

            getData() {
                Server.infoList({xcOrTz: 2, noticeType: 2}).then(res => {
                    console.log(res);
                    if(res.code == 200) {
                        this.list = res.result.records
                        this.total = res.result.total
                    }
                })
            }
        },

        mounted() {
            this.getData()
        }
    }
</script>

<style lang="less" scoped> 
.page {
    width: 100%;
    position: relative;
    .top-bg {
        width: 100%;
        height: 950px;
        position: relative;
        top: 0;
        z-index: 0;
    }
    .bottom-bg {
        width: 100%;
        // height: 1000px;
        position: relative;
        // top: 1170px;
        bottom: 0;
        z-index: 0;
    }
    .content {
        width: 70%;
        margin: 0 auto;
        position: absolute;
        top: 400px;
        left: 50%;
        transform: translateX(-50%);
        background: linear-gradient(#fff 40%, transparent);
        border-radius: 20px;
        .title {
            text-align: center;
            margin: 25px;
            font-size: 22px;
            font-weight: bold;
        }
        .list {
            width: 80%;
            margin: 0 auto;
            .item {
                // width: 100%;
                // padding: 20px 0;
                border-radius: 20px;
                overflow: hidden;
                box-shadow:  0 0 7px #ccc;
                margin: 15px 0;
                background-color: #fff;
                cursor: pointer;

                display: flex;
                align-items: center;
                .left {
                    width: 15%;
                    padding: 20px 0;
                    background-color: #bbb;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-family: '隶书';
                    .num {
                        font-size: 26px;
                        font-weight: bold;
                        color: #F24545;
                    }
                    .time {
                        font-size: 18px;
                        font-weight: bold;
                        color: #777;
                    }
                }
                .right {
                    flex: 1;
                    padding: 0 20px;
                    line-height: 20px;
                    // background-color: #fff;
                }
            }
        }

        ::v-deep .el-pager li.active {
            background-color: #F24545;
            color: #f5f5f5;
            border-radius: 5px;
        }
        ::v-deep .el-pager li {
            color: #F24545;
            min-width: 30px;
            margin: 0 10px;
            &:nth-child(1) {
                margin-left: 0;
            }
            &:nth-last-child(1) {
                margin-right: 0;
            }
        }
    }
}

@media screen and (max-width: 1199px) and (min-width: 600px) {
.page {
    width: 100%;
    position: relative;
    .top-bg {
        width: 100%;
        height: 800px;
        position: relative;
        top: 0;
        z-index: 0;
    }
    .bottom-bg {
        width: 100%;
        // height: 1000px;
        position: relative;
        // top: 1170px;
        bottom: 0;
        z-index: 0;
    }
    .content {
        width: 80%;
        margin: 0 auto;
        position: absolute;
        top: 350px;
        left: 50%;
        transform: translateX(-50%);
        background: linear-gradient(#fff 40%, transparent);
        border-radius: 20px;
        .title {
            text-align: center;
            margin: 25px;
            font-size: 22px;
            font-weight: bold;
        }
        .list {
            width: 85%;
            margin: 0 auto;
            .item {
                // width: 100%;
                // padding: 20px 0;
                border-radius: 20px;
                overflow: hidden;
                box-shadow:  0 0 7px #ccc;
                margin: 15px 0;
                background-color: #fff;
                cursor: pointer;

                display: flex;
                align-items: center;
                .left {
                    width: 15%;
                    padding: 20px 0;
                    background-color: #bbb;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-family: '隶书';
                    .num {
                        font-size: 22px;
                        font-weight: bold;
                        color: #F24545;
                    }
                    .time {
                        font-size: 14px;
                        font-weight: bold;
                        color: #777;
                    }
                }
                .right {
                    flex: 1;
                    padding: 0 20px;
                    // background-color: #fff;
                }
            }
        }

        ::v-deep .el-pager li.active {
            background-color: #F24545;
            color: #f5f5f5;
            border-radius: 5px;
        }
        ::v-deep .el-pager li {
            color: #F24545;
            min-width: 30px;
            margin: 0 10px;
            &:nth-child(1) {
                margin-left: 0;
            }
            &:nth-last-child(1) {
                margin-right: 0;
            }
        }
    }
}
}
</style>