<template>
    <div class="page">
        <img class="top-bg" src="../../assets/image/guanfangzixun/bg.png" alt="">
        <!-- <img class="bottom-bg" src="../../assets/image/bottomBg.png" alt=""> -->
        <div class="content">
            <div class="one">
                <div class="left">
                    <div class="block">
                        <el-carousel trigger="click" height="450px" :interval="2000">
                            <el-carousel-item v-for="item in 5" :key="item">
                                <img class="carousel-img" src="../../assets/image/guanfangzixun/carousel1.png" alt="">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <!-- <div style="width: 0;
                        border-top: 20px solid red;
                        border-right: 20px solid transparent;
                        border-bottom: 20px solid transparent;
                        border-left: 20px solid red;
                        position: relative;">
                        <div style="position: absolute; transform: translate(-50%, -50%);">03</div>
                    </div> -->
                    <div class="mask">
                        <div class="mask-bg"></div>
                        <div class="mask-content">
                            <div class="mask-title">运动强体魄，一起向未来！</div>
                            <div class="mask-desc">本届春季运动会吸引了全校师生广泛参与，无论是在竞技赛场上，还是在趣味运动场地中，大家都全身心的投入到体育运动中，既展现了挑战自我、奋勇争先的可贵品质，也体现了团结协作、齐心共进的团队精神… …</div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="right-item">
                        <div class="item-title">
                            <img src="../../assets/image/guanfangzixun/itemLogo.png" class="item-logo" alt="">
                            <span class="name">赛事通知</span>
                        </div>
                        <ul class="list">
                            <li class="list-item" v-for="(item, index) in gameNoticeList" :key="index" @click="toGameNoticeDetail(item)">
                                <div class="dot"></div>
                                <div class="desc">{{ item.title }}</div>
                                <div class="time">{{ item.createTime.split(' ')[0] }}</div>
                            </li>
                        </ul>
                    </div>
                    <div class="right-item">
                        <div class="item-title">
                            <img src="../../assets/image/guanfangzixun/itemLogo.png" class="item-logo" alt="">
                            <span class="name">官方报道</span>
                        </div>
                        <ul class="list">
                            <li class="list-item" v-for="(item, index) in publicList" :key="index" @click="toPublicDetail(item)">
                                <div class="dot"></div>
                                <div class="desc">我校第二十六届阳光体育节田径运动会... ...</div>
                                <div class="time">2024-5-6</div>
                            </li>
                        </ul>
                        <!-- <ul class="list">
                            <li class="list-item" v-for="(item, index) in gameNoticeList" :key="index" @click="toGameNoticeDetail(item)">
                                <div class="dot"></div>
                                <div class="desc">{{ item.title }}</div>
                                <div class="time">{{ item.createTime.split(' ')[0] }}</div>
                            </li>
                        </ul> -->
                    </div>
                </div>
            </div>
            <div class="two">
                <div class="model">
                    <div class="title">
                        <img src="../../assets/image/guanfangzixun/itemLogo.png" class="item-logo" alt="">
                        <span class="name">精彩时刻</span>
                    </div>
                    <div class="list">
                        <div class="item" v-for="(item, index) in gradeTimeList" :key="index" @click="togradeTimeDetail(item)">
                            <div class="left">
                                <img style="width: 100%; height: 100%;" :src="downloadUrl + item.coverpicture" alt="">
                            </div>
                            <div class="item-right">
                                <div class="item-title">{{ item.title }}</div>
                                <div class="item-desc" v-html="item.content"></div>
                                <div class="time">{{ item.createTime }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { Server } from "@/server";
    export default{
        name: 'gameNotice',
        data() {
            return {
                total: 0,
                gameNoticeList: [],
                gradeTimeList: [],
                downloadUrl: '',

                publicList: [
                    {
                        title: '我校第二十六届阳光体育节田径运动会... ...',
                        time: 2024-5-6
                    },
                    {
                        title: '我校第二十六届阳光体育节田径运动会... ...',
                        time: 2024-5-6
                    }
                ]
            };
        },
        methods: {
            currentPage(page) {
                this.pageNo = page;
            },
            xia() {
                this.pageNo = this.pageNo + 1;
            },
            shang() {
                this.pageNo = this.pageNo - 1;
            },

            toDetail(item) {
                console.log(item);
                if(item == 'one') {
                    // this.$router.push({
                    //     path: '/guanfangZicaidan'
                    // })
                } else if (item == 'two') {
                    this.$router.push({
                        path: '/gameNotice'
                    })
                } else if (item == 'three') {
                    // this.$router.push({
                    //     path: '/guanfangbaodao'
                    // })
                } else if (item == 'four') {
                    this.$router.push({
                        path: '/gradeTime'
                    })
                } else if (item == 'five') {
                    // this.$router.push({
                    //     path: '/meitibaodao'
                    // })
                }
                 
            },

            getData() {
                Server.infoList({xcOrTz: 2, noticeType: 2}).then(res => {
                    console.log(res);
                    if(res.code == 200) {
                        this.gameNoticeList = res.result.records
                    }
                })
                Server.infoList({xcOrTz: 1, publicityType: 1}).then(res => {
                    console.log(res);
                    if(res.code == 200) {
                        this.gradeTimeList = res.result.records
                    }
                })
            },
            togradeTimeDetail(item) {
                console.log(item);
                this.$router.push({
                    path: '/gradeTimeDetail',
                    query: {
                        id: item.id
                    }
                })
            },
            toGameNoticeDetail(item) {
                console.log(item);
                this.$router.push({
                    path: '/gameNoticeDetail',
                    query: {
                        id: item.id
                    }
                })
            },
            toPublicDetail(item) {
                this.$router.push({
                    path: '/publicReportDetail',
                    query: {
                        title: item.title,
                        createTime : item.time
                    }
                })
            }
        },

        mounted() {
            this.getData()
            this.downloadUrl = this.$downloadUrl
        }
    }
</script>

<style lang="less" scoped> 
.page {
    width: 100%;
    position: relative;
    background-color: #f5f5f5;
    // position: relative;
    // margin-bottom: 785px;
    .top-bg {
        width: 100%;
        // height: 950px;
        position: absolute;
        top: 0;
        // z-index: 0;
    }
    .content {
        width: 65%;
        padding-top: 400px;
        margin: 0 auto 200px;
        position: relative;
        z-index: 10;
        // position: absolute;
        // top: 400px;
        // left: 50%;
        // transform: translateX(-50%);
        
        .one {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left {
                width: 65%;
                position: relative;
                .carousel-img {
                    width: 100%;
                    height: 100%;
                }
                ::v-deep .el-carousel__indicators--horizontal {
                    bottom: 52px;
                }
                ::v-deep .el-carousel__button {
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background-color: #000;
                    
                }
                ::v-deep .el-carousel__indicator.is-active {
                    button {
                        background-color: #fff;
                        z-index: 100;
                    }
                }
                .mask {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 80px;
                    z-index: 10;
                    .mask-bg {
                        width: 100%;
                        height: 100%;
                        background-color: #000;
                        opacity: .6;
                    }
                    .mask-content {
                        width: 95%;
                        height: 100%;
                        position: absolute;
                        top: 0px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        color: #f5f5f5;
                        .mask-title {
                            margin-left: 3%;
                            font-size: 14px;
                        }
                        .mask-desc {
                            margin-top: 10px;
                            margin-left: 3%;
                            font-size: 12px;
                            text-indent: 2em;
                        }
                    }
                }
            }
            .right {
                height: 450px;
                width: 35%;
                margin-left: 15px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .right-item {
                    height: 48.5%;
                    background-color: #fff;
                    padding: 10px;
                    box-sizing: border-box;

                    overflow: hidden;
                    .item-title {
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid #ddd;
                        padding-bottom: 5px;
                        .item-logo {
                            width: 13px;
                            height: 15px;
                            margin: 0 10px;
                        }
                        .name {
                            font-size: 14px;
                            font-weight: bold;
                        }
                    }
                    .list {
                        .list-item {
                            display: flex;
                            align-items: center;
                            padding-top: 10px;
                            cursor: pointer;
                            .dot {
                                width: 5px;
                                height: 5px;
                                background-color: #000;
                                border-radius: 50%;
                            }
                            .desc {
                                font-size: 12px;
                                flex: 1;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                margin: 0 10px;
                                letter-spacing: 1px;
                            }
                            .time {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
        .two {
            margin-top: 30px;
            .model {
                .title {
                    display: flex;
                    align-items: center;
                    // border-bottom: 1px solid #ddd;
                    padding-bottom: 5px;
                    .item-logo {
                        width: 20px;
                        height: 23px;
                        margin: 0 10px;
                    }
                    .name {
                        // font-size: 14px;
                        font-weight: bold;
                    }
                }
                .list {
                    padding: 10px;
                    box-sizing: border-box;
                    background-color: #fff;
                    margin-top: 20px;
                    .item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        .left {
                            width: 250px;
                            height: 150px;
                            background-color: #ddd;
                        }
                        .item-right {
                            flex: 1;
                            height: 150px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            margin: 15px 20px;
                            font-size: 14px;
                            font-weight: bold;
                            .item-title {
                                margin: 15px 0;
                            }
                            .item-desc {
                                word-break: break-all;
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                p {
                                    span {
                                        &::before {
                                            content: '';
                                            display: block;
                                            all: revert;
                                        }
                                        font-size: 14px !important;
                                        line-height: 20px !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1199px) and (min-width: 600px) {
.page {
    width: 100%;
    position: relative;
    .top-bg {
        width: 100%;
        // height: 800px;
        position: relative;
        top: 0;
        z-index: 0;
    }
    .content {
        width: 80%;
        margin: 0 auto;
        position: absolute;
        top: 350px;
        left: 50%;
        transform: translateX(-50%);
        background: linear-gradient(#fff 40%, transparent);
        border-radius: 20px;
        
    }
}
}
</style>