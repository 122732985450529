<template>
    <div class="page">
        <img class="top-bg" src="../../assets/image/jingcaizhongyunhui/topBg.png" alt="">
        <div class="content">
            <div class="tab-box">
                <el-tabs v-model="activeName" @tab-click="handleClick" class="" :stretch="true">
                    <el-tab-pane label="中运会简介" name="1">
                        <div class="tab-content">
                            <el-breadcrumb separator-class="el-icon-arrow-right">
                                <el-breadcrumb-item style="font-weight: bold;">精彩中运会</el-breadcrumb-item>
                                <el-breadcrumb-item>中运会简介</el-breadcrumb-item>
                            </el-breadcrumb>

                            <div class="title">中运会简介</div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="赛事服务" name="2">
                        <div class="tab-content">
                            <el-breadcrumb separator-class="el-icon-arrow-right">
                                <el-breadcrumb-item style="font-weight: bold;">精彩中运会</el-breadcrumb-item>
                                <el-breadcrumb-item>赛事服务</el-breadcrumb-item>
                            </el-breadcrumb>

                            <div class="title">赛事服务</div>
                        </div>
                    </el-tab-pane>
                    <!-- <el-tab-pane label="赛事文化" name="3">
                        <div class="tab-content">
                            <el-breadcrumb separator-class="el-icon-arrow-right">
                                <el-breadcrumb-item style="font-weight: bold;">精彩中运会</el-breadcrumb-item>
                                <el-breadcrumb-item>赛事文化</el-breadcrumb-item>
                            </el-breadcrumb>

                            <div class="title">赛事文化</div>
                        </div>
                    </el-tab-pane> -->
                    <el-tab-pane label="宣传片" name="3">
                        <div class="tab-content">
                            <el-breadcrumb separator-class="el-icon-arrow-right">
                                <el-breadcrumb-item style="font-weight: bold;">精彩中运会</el-breadcrumb-item>
                                <el-breadcrumb-item>宣传片</el-breadcrumb-item>
                            </el-breadcrumb>

                            <div class="title">宣传片</div>
                        </div>
                    </el-tab-pane>
                </el-tabs>

                <div class="video">
                    <video class="video-content" controls>
                        <source src="../../assets/image/jingcaizhongyunhui/video.mp4" type="video/mp4">
                    </video>
                </div>
                <div class="word">
                    <p>
                        淄博市中学生运动会是一项备受瞩目的体育赛事，旨在展示淄博市中学生体育竞技水平和精神风貌，同时加强区县间、高中学校间的体育工作交流，促进淄博市学校体育工作的不断提升。
                    </p>
                    <p>
                        这项运动会历史悠久，自创办以来，规模逐渐扩大，参赛人数和比赛项目也不断增加。如今，淄博市中学生运动会已经成为淄博市水平最高、参赛人数最多的中学生体育赛事之一。
                    </p>
                    <p>
                        在淄博市中学生运动会中，来自全市各区县、各高中学校的优秀运动员们齐聚一堂，共同竞技，争夺荣誉。比赛项目涵盖田径、游泳、篮球、足球、排球、乒乓球等多个领域，既有个人项目，也有团体项目，让运动员们有机会展示自己的才华和实力。
                    </p>
                    <p>
                        此外，淄博市中学生运动会还注重体育精神的培养和传承。在比赛中，运动员们不仅展现了高超的竞技水平，更体现了团结协作、顽强拼搏、勇攀高峰的体育精神。这些精神品质不仅对于运动员们的个人成长具有重要意义，也为淄博市体育事业的发展注入了新的活力。
                    </p>
                    <p>
                        总之，淄博市中学生运动会是一项具有重要意义的体育赛事，它展示了淄博市中学生体育竞技水平和精神风貌，促进了区县间、高中学校间的体育工作交流，推动了淄博市学校体育工作的不断发展。
                    </p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import router from "@/router";
import { Server } from "@/server";
    export default{
        name: 'jingcaizhongyunhui',
        data() {
            return {
                total: 0,
                list: [],

                activeName: '1',
            };
        },
        created() {
            console.log(this.$route.query);
            if(this.$route.query) {
                console.log(this.$route.query.activeName);
                this.activeName = this.$route.query.activeName
                console.log(this.activeName);
            }
            
        },
        
        watch: {
            $route(newVal, oldVal) {
                console.log(this.$route);
                this.activeName = newVal.query.activeName
            },
        },
        methods: {
            handleClick(tab, event) {
                console.log(tab.name);
                this.activeName = tab.name
            },

            toDetail(item) {
                console.log(item);
                if(item == 'one') {
                    // this.$router.push({
                    //     path: '/guanfangZicaidan'
                    // })
                } else if (item == 'two') {
                    this.$router.push({
                        path: '/culture'
                    })
                } else if (item == 'three') {
                    // this.$router.push({
                    //     path: '/guanfangbaodao'
                    // })
                } else if (item == 'four') {
                    // this.$router.push({
                    //     path: '/gradeTime'
                    // })
                }
                 
            },

            getData() {
                Server.infoList({xcOrTz: 2, noticeType: 2}).then(res => {
                    console.log(res);
                    if(res.code == 200) {
                        this.list = res.result.records
                        this.total = res.result.total
                    }
                })
            }
        },

        mounted() {
            
            this.getData()
        }
    }
</script>

<style lang="less" scoped> 
.page {
    width: 100%;
    position: relative;
    background-color: #f5f5f5;
    .top-bg {
        width: 100%;
        // height: 950px;
        // position: relative;
        // top: 0;
        // z-index: 0;
    }
    .content {
        width: 65%;
        padding: 0 2% 20px;
        box-sizing: border-box;
        margin: 0 auto 50px;
        background-color: #fff;
        .tab-box {
            ::v-deep .el-tabs__header {
                // width: 100%;
                // left: -20vw;
                .el-tabs__nav-scroll {
                    // width: 60%;
                    // margin: 0 auto;
                }
            }
            ::v-deep .el-tabs__nav {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .el-tabs__active-bar {
                    // width: 25% !important;
                    height: 3px;
                    background-color: #F44336;
                }
                .el-tabs__item {
                    padding: 0;
                    width: 25%;
                    text-align: center;
                    &:hover {
                        color: #F44336;
                    }
                }
                .el-tabs__item.is-active {
                    color: #444;
                }
            }

            .tab-content {
                .title {
                    margin: 20px 0;
                    text-align: center;
                    font-size: 24px;
                    font-weight: bold;
                }
            }

            .video {
                width: 80%;
                height: 360px;
                background-color: #F44336;
                margin: 0 auto 20px;
                .video-content {
                    width: 100%;
                    height: 100%;
                }
            }

            .word {
                text-indent: 2em;
                width: 80%;
                margin: 50px auto;
                line-height: 35px;
                font-size: 14px;
                font-weight: bold;
            }
        }
    }
}

@media screen and (max-width: 1199px) and (min-width: 600px) {
    .page {
        width: 100%;
        position: relative;
        .top-bg {
            width: 100%;
        }
        
    }
}
</style>