import { render, staticRenderFns } from "./xiangmutuiguang.vue?vue&type=template&id=33c14872&scoped=true"
import script from "./xiangmutuiguang.vue?vue&type=script&lang=js"
export * from "./xiangmutuiguang.vue?vue&type=script&lang=js"
import style0 from "./xiangmutuiguang.vue?vue&type=style&index=0&id=33c14872&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33c14872",
  null
  
)

export default component.exports