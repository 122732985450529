<template>
  <div>   <el-breadcrumb separator-class="el-icon-arrow-right"  >
    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item :to="{ path: '/rencaizhengce' }">人才政策</el-breadcrumb-item>
    <el-breadcrumb-item :to="{ path: '/search' }">查询</el-breadcrumb-item>
    <el-breadcrumb-item >查询结果</el-breadcrumb-item>
  </el-breadcrumb>
    <div class="content">
      <div v-for="item in list" :key="item.id" class="view" @click="detail(item.id)">
        <div class="title">
          <span >{{item.fbt}}</span>
          <span style="display: block;font-size: 14px;padding-top: 15px">{{item.title}}</span>
        </div>
      </div>
      <div style="text-align: center;margin-top: 20px;">
        <el-pagination
                layout="prev, pager, next"
                :total="total"
                @current-change="currentPage"
                @prev-click="shang"
                @next-click="xia">
        </el-pagination>
      </div>
    </div>
  </div>

</template>

<script>
import { Server} from "@/server";
export default {
  name: "notice",
  data() {
    return {
      newpolicy: [],//政策
      newcheckList: [],//人才
     /* newcheckcompanyList:[],
      newpolicycompany:[],*/
      pageSize: 10,
      pageNo: 1,
      keyword:"",
      total: 0,
      type:"",
      list: [],//列表
    };
  },
  watch:{
    pageNo(){
      this.getlist()
    }
  },
  methods: {
    detail(id){
      this.$router.push({path:'/searchlistdetail', query: {id: id}})
    },
    currentPage(page){
      this.pageNo = page
    },
    xia(){
      this.pageNo = this.pageNo + 1
    },
    shang(){
      this.pageNo = this.pageNo - 1
    },
    getlist(){
      Server.fwZcxzList({
        status: 1,
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        rclb: this.newcheckList,
        zcdx:'人才',
        zcfl: this.newpolicy,
       /* dwlb:this.this.newcheckcompanyList,
        zczcfx:this.newpolicycompany,*/
      }).then(res=>{
    		if(res.code == 200){
          this.list = res.result.records
          this.total = res.result.total
        }
    	})
    },
    getKeywordlist(){
       this.list=[]
       Server.fwKeywordList({
       pageSize: this.pageSize,
       pageNo: this.pageNo,
       keyword: this.keyword,
         type:this.type
     }).then(res=>{
       console.log(res)
       if(res.code == 200){
         this.list = res.result.records
         this.total = res.result.total
       }
     })
    }
  },
  mounted() {
    this.newpolicy = this.$route.query.newpolicy//政策
    this.newcheckList = this.$route.query.newcheckList//人才
    this.keyword = this.$route.query.keyword
    this.newpolicycompany=this.$route.query.newpolicycompany
    this.newcheckcompanyList = this.$route.query.newcheckcompanyList
    this.type = this.$route.query.type
    if(this.keyword!=""){
      this.getKeywordlist()
    }
    if(this.keyword==undefined|| this.keyword==""){
      this.getlist()
    }
  },
};
</script>

<style lang="less" scoped>
  .el-breadcrumb{
    margin: 10px 0 10px 0;
  }
  .content {
  padding: 30px 0;
  .view {
    padding: 20px;
    background-color: #cccccc;
    cursor: pointer;
    .text {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      word-break: break-all;
    }
    .title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      font-size: 20px;
    }
  }
  .view:nth-child(2n) {
    background-color: #ffffff;
  }
  .view:hover {
    opacity: 0.8;
  }
}
</style>
