<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>国际联络</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="content">
            <div
                v-for="item in list"
                :key="item.type"
                class="view"
                @click="detail(item)"
            >
                <div class="title">
                    <span style="color: blue; margin-right: 10px"
                        >[{{ item.createTime }}]</span
                    >
                    <span>{{ item.title }}</span>
                </div>
            </div>
            <div style="text-align: center; margin-top: 20px">
                <el-pagination
                    layout="prev, pager, next"
                    :total="total"
                    @current-change="currentPage"
                    @prev-click="shang"
                    @next-click="xia"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { Server } from "@/server";
// import { type } from "os";
export default {
    name: "kuajingfuwu",
    data() {
        return {
            pageSize: 10,
            pageNo: 1,
            total: 0,
            type: "",
            list: [], //列表
        };
    },
    watch: {
        pageNo() {
            this.getgjll();
        },
    },
    methods: {
        detail(item) {
            this.$router.push({
                path: "/guojilianluoDetail",
                query: {
                    type: item.type,
                    id: item.id,
                },
            });
        },
        currentPage(page) {
            this.pageNo = page;
        },
        xia() {
            this.pageNo = this.pageNo + 1;
        },
        shang() {
            this.pageNo = this.pageNo - 1;
        },
        getgjll() {
            Server.infoList({
                type: 7,
                status: 1,
                pageSize: this.pageSize,
                pageNo: this.pageNo,
            }).then((res) => {
                if (res.code == 200) {
                    this.list = res.result.records;
                    this.total = res.result.total;
                }
            });
        },
    },
    mounted() {
        this.id = this.$route.query.id;
        this.getgjll();
        this.$store.state.CommonHeader.tab = 11;
    },
};
</script>

<style lang="less" scoped>
.el-breadcrumb {
    padding: 20px 0 10px 10px;
}
.content {
    padding: 30px 0;
    .view {
        padding: 20px;
        background-color: #cccccc;
        cursor: pointer;
        .text {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            word-break: break-all;
        }
        .title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            font-size: 20px;
        }
    }
    .view:nth-child(2n) {
        background-color: #ffffff;
    }
    .view:hover {
        opacity: 0.8;
    }
}
</style>
