<template>
    
    <div id="app">
        <div id="mainContainer">
            
            <el-header style="width: 100%">
                <Header />
            </el-header>
            <router-view class="router-view"></router-view>
            <el-footer class="setfooter">
                <Footer />
            </el-footer>
        </div>
        
    </div>
</template>

<script>
import Header from "./components/header";
import Footer from "./components/footer";
import { RouterView } from "vue-router";
export default {
    name: "App",
    components: {
    Header,
    Footer,
    RouterView
},
      
    mounted() {
        // var browerWidth = window.innerWidth; //浏览器可视宽度
        // var baseWidth = 1920; //设计稿宽度
        // var zoomValue = browerWidth / baseWidth; //缩放比例计算
        // // document.getElementById("mainContainer").style.transform =
        // //   "scale(" + zoomValue + "," + zoomValue + ")"; //mainContainer为主容器id
        // // document.getElementsByTagName("body")[0].style.transform =
        // //   "scale(" + zoomValue + "," + zoomValue + ")"; //mainContainer为主容器id
        // window.onresize = function () {
        //     console.log("browerWidth", window.innerWidth);
        //     //窗口尺寸变化时，重新计算和缩放
        //     browerWidth = window.innerWidth;
        //     zoomValue = window.innerWidth / baseWidth;
        //     // document.getElementById("mainContainer").style.transform =
        //     //   "scale(" + zoomValue + "," + zoomValue + ")";
        //     if (window.innerWidth < 600) {
        //         // document.getElementsByTagName("body")[0].style.transform =
        //         //   "scale(" + zoomValue + "," + zoomValue + ")";
        //         document.getElementById("mainContainer").style.transform =
        //             "scale(" + zoomValue + "," + zoomValue + ")";
        //     } else {
        //         // document.getElementsByTagName("body")[0].style.transform =
        //         //   "scale(" + 1 + "," + 1 + ")";
        //         document.getElementById("mainContainer").style.transform =
        //             "scale(" + 1 + "," + 1 + ")";
        //     }
        // };
        // this.setZoom();
    },
    methods: {},
};
</script>

<style lang="less">
html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    color: #333;
    background-color: #f5f5f5;
}
#app {
    width: 100%;
    height: auto;
    min-height: calc(100vh - 10.4vh);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f5f5f5;
    /* background-color: red; */
    /* width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start; */

    .setfooter {
        position: relative;
        bottom: -4px;
    }
}
#mainContainer {
    position: relative;
    background-color: #f5f5f5;

    /* background-color: yellow  ;
  position: absolute;
  left: 0;

  top: 0; */
    /* width: 100%;
  height: 100%;
  margin: auto; */
}
.router-view {
    width: 1120px;
    margin: 0 auto;
    background-color: #fff;
}
.el-container {
    width: auto;
    background-color: #ffffff;
}
.el-header {
    width: auto;
    height: auto;
    height: 70px !important;
    width: 1120px;
    margin: 0 auto;

    padding: 0 !important;
    position: relative;
    .bg {
        background-color: #005190;
        position: absolute;
        top: 117px;
        width: 100%;
        height: 40px;
        z-index: 0;
    }
}
.el-main {
    width: auto;
    height: auto;
    width: 1120px;
    margin: 0 auto;
}
.el-footer {
    width: 100%;
}
.header-banner {
    width: 100%;
    height: 336px;
}



/* pad */

@media screen and (max-width: 1199px) and (min-width: 600px) {
    .router-view {
        width: 1000px;
        margin: 0 auto;
    }
    .el-container {
        width: auto;
        background-color: #ffffff;
    }
    .el-header {
        width: auto;
        height: auto;
        // height: 170px !important;
        width: 1000px;
        margin: 0 auto;

        padding: 0 !important;
    }

    .el-main {
        width: auto;
        height: auto;
        width: 1000px;
        margin: 0 auto;
    }
    .el-footer {
        width: 100%;
        height: 180px !important;
    }
}
</style>
